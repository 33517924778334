.analytic-statistic {
    display: flex;
    justify-content: center;
    padding: 1rem;

    width: 150px;
    height: 100%;

    .label {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 0.9rem;
    }

    .value {
        margin-bottom: 0;
        font-weight: 900;
        font-size: 1rem;
    }

    &.light {
        background: #e9ecef;

        .label, .value {
            color: #8898aa;
        }
    }

    &.dark {
        background: #8898aa;

        .label, .value {
            color: white;
        }
    }
}
