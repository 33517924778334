.react-tel-input .form-control{
  display: block;
  width: 100% !important;
  height: calc(1.5em + 1.25rem + 7px) !important;
  padding: 0.625rem 2.75rem;
  font-size:1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 3px 2px rgba(233, 236, 239 , 0.005);
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    &:focus{
      border-color: #8965e0;
    }
}