.days-list-tooltip {
    .bs-tooltip-right .arrow::before,
    .bs-tooltip-auto[x-placement^="right"] .arrow::before {
        border-right-color: white;
        background: transparent;
    }

    .bs-tooltip-left .arrow::before,
    .bs-tooltip-auto[x-placement^="left"] .arrow::before {
        border-left-color: white;
        background: transparent;
    }

    .tooltip {
        &.show {
            opacity: 1;
        }

        .tooltip-inner {
            // min-width: 250px;
            max-width: 350px;
            background-color: white;
            padding: 10px 15px 10px 0;
            box-shadow: 0px 15px 35px #32325d33;
        }
    }

    .days-list-tooltip-container {
        color: black;
    }
}