.profile-card {
	.profile-scroll {
		overflow-x: hidden;
	}

	.profile-scroll::-webkit-scrollbar-thumb {
		background-color: #5026d9;
	}

	.profile-scroll::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		border: 1px solid #5026d9;
	}

	.profile-scroll::-webkit-scrollbar-track {
		background-color: hsla(0, 3%, 44%, 0.75);
	}

	.menu-scroll {
		overflow-y: "auto";
		overflow-x: "hidden";
		height: calc(100% - 1rem);
	}

	.columns-list-scroll {
		overflow-y: "auto";
		height: calc(100% - 2rem);
	}

	a {
		border-radius: 0.25rem;

		&.active {
			color: white;
			background-color: #010a63;
		}
	}
}

@media (max-width: 767px) {
	.profile-card {
		height: auto !important;
	}

	.profile-content {
		flex-direction: column-reverse;
	}

	.drawer {
		position: relative !important;
	}
}