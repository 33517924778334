.segments-table {
    td {
        padding: 0.5rem 0.5rem !important;
        vertical-align: middle;
        text-align: center !important;

        .time-input {
            height: 24px;
            min-width: 70px;
            border-radius: .4375rem !important;
            border-style: solid !important;
        }

        .duration-input {
            height: 24px;
            text-align: center;
            min-width: 60px;
        }

        .rc-select {
            width: 80px !important;
            text-align: start;
            border-radius: 0.4375rem !important;

            .rc-select-clear {
                right: 10px;
            }

            &.w-100 {
                width: 100% !important;

                .rc-select-selector {
                    max-width: 100% !important;
                }
            }
        }

        .rc-select-single:not(.rc-select-customize-input) .rc-select-selector,
        .form-control {
            height: 24px !important;
            padding: 0px !important;
            min-height: 24px;
            max-width: 80px;
            border-radius: 0.4375rem !important;
        }

        input[type="text"] {
            width: 60px;
            border-radius: 0.4375rem !important;
            padding: 0.5rem !important;
        }


    }
}