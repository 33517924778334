.time-clock-my {
    height: calc(100% - 24px);
}

.requests-margin {
    margin: 0;
}

@media(max-width: 991px) {
    .time-clock-my {
        height: auto;
    }

    .requests-margin {
        margin: 20px 0 20px 0;
    }
}