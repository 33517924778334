.companies-image-upload-input{
    margin-bottom: 1rem;
    .form-control{
        height: auto;
    }

    input[type=file]::file-selector-button {
        display: hidden;
        margin-right: 20px;
        border: none;
        background: #010a63;
        padding: 10px 20px;
        border-radius: 3px;
        color: #fff;
        cursor: pointer;
        transition: #010a63 .2s ease-in-out;
    }

    input[type=file]::file-selector-button:hover {
        background: #4056ce;
    }  
    
    .logo-label{
        color: #525f7f;
        display: inline-block;
        margin-bottom: 0.5rem;
    }

    .logo-bg-image{
        width: 6.25rem;
        height:6.25rem;
        object-fit: contain;
    }
}