.my-timesheet-wrapper-table {
    .custom-control.custom-checkbox {
        width: 16px;
        min-height: auto;
    }

    .table {
        th, td {
            padding: 0.5rem 0.5rem !important;
        }

        .thead-light {
            th {
                background-color: rgb(233, 233, 233) !important;
            }
        }
        
        .timesheet-table-container {
            padding: 0.15rem!important;
            background-color: #f6f6f6!important;
        }

        tr {
            &.unapproved {
                background-color: rgb(240, 249, 255);
            }

            &.misspunch {
                background-color: rgb(255, 228, 230);
            }

            &.expanded {
                background-color: #5d69f41a;
                color: #5d69f4;

                &.misspunch {
                    background-color: rgb(254, 205, 211);
                }
            }

            td {
                span {
                    &.terminated{
                        color: rgb(239, 124, 137);
                    }
                }
            }
        }
    }
}
