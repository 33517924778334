.configuration {
    .scroll {
        overflow-x: hidden;
    }

    .scroll::-webkit-scrollbar-thumb {
        background-color: #5026d9;
    }

    .scroll::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        border: 1px solid #5026d9;
    }

    .scroll::-webkit-scrollbar-track {
        background-color: hsla(0, 3%, 44%, 0.75);
    }
}