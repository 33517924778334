
.upload-input::-webkit-file-upload-button {
    visibility: hidden;
}

.upload-input{
    color: transparent;
}

.upload-input::before {
    content: 'Drop files here to upload';
    color: #dee2e6;
    border: 1px dashed #dee2e6;
    border-radius: 5px;
    padding: 15px 50px;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: 600;
    width:100%;
    background-color: white;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-input:hover::before {
    border-color: #dee2e6;
}

.upload-input:active {
    outline: 0;
}

.upload-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}
  
