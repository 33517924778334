.post-likes-modal {
    .posts-avatar {
        color: #fff;
        background-color: #adb5bd;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        border-radius: 0.375rem;
        height: 42px;
        width: 42px;
    }

    .likes-list-modal {
        height: 300px;
        overflow-y: auto;
    }
}
