.post-widget {
    .ql-toolbar.ql-snow {
        padding: 0px 10px !important;
    }

    .ql-container.ql-snow {
        height: 81px !important;
    }

    .posts-container {
        .send-button {
            width: 54px;
            height: 34px;
        }
    }
}
