.history-accordion-container {
    margin: 5px;
    width: 100%;
    // max-width: 850px;   

    .history-accordion-button {
        width: 100%;
        border: 1px solid rgb(235, 235, 235);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 8px 5px;
        background-color: rgb(243, 241, 241);
    }

    .history-accordion-panel {
        width: 100%;
        height: 380px;
        overflow-y: auto;
        padding: 6px 12px;
        border: 1px solid rgb(235, 235, 235);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top: none;
    }
}
