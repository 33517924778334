.timesheet-weekly {
    .timesheet-table {
        .table-responsive {
            min-height: 200px;
        }

        table {

            tfoot  {
                &, tr:hover {
                    background-color: #dadbdc;
                    color: #000000b5;
                }
            }
        }
    }
}

.aaaabbbb {
    &__toggle {
        // padding: 0px;
        margin-right: 0 !important;

        &,
        &:hover,
        &:focus {
            box-shadow: none;
            background-color: transparent;
            border-color: transparent;
            transform: none;
            margin-right: 0;
            border: 0;
            padding: 4px;
        }
    }

    .dropdown-menu.show {
        padding: 0px;
    }

    &__item {
        border-radius: 1rem;
        height: 24px;
        width: 24px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: 2px solid transparent;
        font-size: 0.9rem;

        &-info {
            background-color: (103, 103, 103);
            border-color: (103, 103, 103);
            color: #525f7f;
        }

        &-summary {
            background-color: (103, 103, 103);
            border-color: (103, 103, 103);
            color: #525f7f;
        }

        &-approve {
            background-color: rgb(74, 222, 128);
            border-color: rgb(74, 222, 128);
            color: white;
        }

        &-unapprove {
            background-color: rgb(248, 113, 113);
            border-color: rgb(248, 113, 113);
            color: white;
        }

        &-lock {
            background-color: (103, 103, 103);
            border-color: (103, 103, 103);
            color: #525f7f;
        }

        &-unlock {
            background-color: (103, 103, 103);
            border-color: (103, 103, 103);
            color: #525f7f;
        }
    }
}