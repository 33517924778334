.history-drawer{
    &.drawer .card .card-header{
        height: 100px;
        border-bottom: 0px !important;
        padding-left: 0;
        
    }

    &.drawer .card > .content{
        margin-top: 137px;
        max-height: calc(100% - 137px);
        height: 100%;
        overflow-y: auto;
        overflow-x: none !important;
    }

    .content{
        .card-body{
            padding:0 1.5rem;
        }
    }

    .shifts-table td {
        padding: 7px !important;
        vertical-align: middle;
        .rc-select {
            width: 80px !important;
            text-align: start;
            .rc-select-clear{
                right: 10px;
            }
        }
        .rc-select-single:not(.rc-select-customize-input) .rc-select-selector, .form-control{
            height: 20px !important;
            padding: 5px !important;
            min-height: 20px;
            max-width: 80px;
        }
        input[type="number" i] {
            width: 60px;
        }
        .current-time-input {
            min-width: 80px !important;
            width: 80px !important;
            text-align: center;
        }
    }

    .pay-code-select .rc-select-selection-placeholder {
        text-align: start;
    }
}

.view-logs-tooltip {
    .bs-tooltip-left .arrow::before, 
    .bs-tooltip-left[x-placement^="left"] .arrow::before {
        border-left-color: white;
        background: transparent;
    }
    
    .tooltip{
        &.show{
            opacity:1;
        }

        .tooltip-inner {
            min-width: 350px;
            max-width: none;
            max-height: 470px;
            overflow: auto;
            background-color: #fff;
            padding: 1.2rem;
            box-shadow: 0px 15px 35px #32325D33;   
            color: #000;

            .item {
                border: 1px dashed #dee2e6;
            }

            table {
                tr {
                    padding: 5px;
                }
            }
        }
    }
}