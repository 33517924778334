.summary-drawer {
	.ant-drawer-header {
		@apply py-5;

		color: #262626;
		font-size: 1.62rem;
		max-height: 4rem;
	}

	.info-text {
		@apply py-2 px-3 rounded mb-2;

		border: 2px solid #bcbcbc;
		font-size: 1.15rem;

		.text {
			@apply font-semibold pr-1;

			color: #6d7177;
		}

		.date {
			color: #6d7177;
		}
	}

	.ant-table-small .ant-table-thead > tr > th {
		background-color: #e7e6e6;
		border-bottom: 0px;
	}

	.ant-table {
		@apply mb-5;

		.ant-table-thead > tr > th {
			text-align: left;
		}

		.ant-table-container table > thead {
			> tr:first-child th:first-child {
				@apply rounded-none;
			}

			> tr:first-child th:last-child {
				@apply rounded-none;
			}

			.ant-table-cell::before {
				@apply hidden;
			}
		}

		.ant-table-tbody > tr > td {
			@apply text-left;

			border-bottom-width: 1px;
		}

		.ant-table-summary {
			@apply font-semibold;

			background-color: #fafafa;
			color: #595959;
		}

		.reg-text {
			@apply text-primary-color;
		}

		.ot-text {
			color: #ff0101;
		}

		.absence-text {
			color: #ff5003;
		}

		.unpaid-breaks-text {
			color: #643fe5;
		}
	}

	.total {
		@apply grid grid-cols-4 text-primary-color font-semibold rounded mb-8;

		background-color: #d9f6ff;
		border: 1px solid #bcbcbc;

		div {
			@apply p-2;
		}
	}

	.ant-btn {
		@apply flex items-center rounded py-2 px-8;

		background-color: #ececec;
		color: #6d7177;
		font-size: 1rem;

		&:hover {
			background-color: #ececec;
			color: #6d7177;
			font-size: 1rem;
		}
	}
}
