.image-tooltip{
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before{
        border-left-color: white;
        background: transparent;
    }
    
    .tooltip{
        &.show{
            opacity:1;
        }

        .tooltip-inner {
            min-width: 350px;
            max-width: none;
            background-color: #fff;
            padding: 1.2rem;
            box-shadow: 0px 15px 35px #32325D33;
        }
    }
}