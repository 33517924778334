.main-content {
  height: 100%;

  .admin-navbar {
    margin-bottom: 0px;
  }

  > .content {
    height: calc(100% - 94px);
    padding: 0px 30px;
    // transition: padding-right 0.2s ease-in;
  }

  &.sider-open {
    > .content {
      padding-right: 0px;
    }
  }
}

@media screen and (orientation: landscape) and (max-width: 916px) {
  .main-content {
    > .content {
      height: 110vh;
    }
  }
}

@media (max-width: 625px) {
  .main-content {
    > .content {
      height: 100%;
      padding: 0px 10px;
    }
  }
}
