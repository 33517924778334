.qr-login{
    width: 290px;
    background-color: #f4f2f2;
    padding: 20px;
    border-radius: 5px;
    
    .btn{
        width: 77px;
        height: 77px;
        margin: 5px;
        font-size: 2.7rem;
        text-align: center;
        color: #979797;
        font-weight: 900;
        background-color: #fff;
        box-shadow: none;
        border:0px;
    }

    @keyframes cursor-blink {
        0% {
          opacity: 0;
        }
      }

    .form-group{
        div.input{
            width:247px;
            margin-left: 3px;
            display: flex;
            align-items: center;
            height: 50px;
            padding: 0.625rem 0.75rem;
            font-size: 1.09rem;
            font-weight: 400;
            line-height: 1.5;
            color: #8898aa;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #dee2e6;
            border-radius: 0.25rem;
            border-width: 0.17rem !important;
            transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }

        div.input-with-placeholder{
            width:247px;
            margin-left: 3px;
            display: flex;
            align-items: center;
            height: 50px;
            padding: 0.625rem 0.75rem;
            font-size: 1.09rem;
            font-weight: 400;
            line-height: 1.5;
            color: #8898aa;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #dee2e6;
            border-radius: 0.25rem;
            border-width: 0.17rem !important;
            transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            
            &::before{
                animation: cursor-blink 1.5s steps(2) infinite;
                content: "";
                width: 2px;
                height: 1.2rem;
                margin-left: 0.1rem;
                background: #000;
                display: inline-block;
            }
        }

        div.active-input{
            width:247px;
            margin-left: 3px;
            display: flex;
            align-items: center;
            height: 50px;
            padding: 0.625rem 0.75rem;
            font-size: 1.80rem;
            font-weight: 400;
            line-height: 1.5;
            color: #8898aa;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #dee2e6;
            border-radius: 0.25rem;
            border-width: 0.17rem !important;
            transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);

            &::after{
                animation: cursor-blink 1.5s steps(2) infinite;
                content: "";
                width: 2px;
                height: 1.8rem;
                margin-left: 0.1rem;
                background: #000;
                display: inline-block;
            }
        }

        div.input-with-value {
            width:247px;
            margin-left: 3px;
            display: flex;
            align-items: center;
            height: 50px;
            padding: 0.625rem 0.75rem;
            font-size: 1.80rem;
            font-weight: 400;
            line-height: 1.5;
            color: #8898aa;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #dee2e6;
            border-radius: 0.25rem;
            border-width: 0.17rem !important;
            transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }
    }

    .border-invalid-feedback{
        border-color: #fb6340 !important;
        border-width: 0.15rem !important;
    }

   
    .invalid-feedback{
        font-weight: 600;
        margin-left: 3px;
        background: white;
        width: 247px;
        border-radius: 2px;
        padding:5px 10px;
        box-shadow: 0 2px 2px rgb(245, 195, 195);
    }
}

