.report-desktop {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  .menu {
    width: 20%;
    border-right: 1px solid #f0f0f0;
    height: 100%;
    overflow-y: auto;
    position: relative;
    border-radius: 1rem 0;

    &__title {
      font-weight: bold;
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 1;
    }

    &__list {
      list-style: none;
      padding: 0.5rem;
      margin: 0;
    }

    &__add-folder-icon {
      cursor: pointer;
      padding: 0.5rem;

      &:hover {
        background-color: #f0f0f0;
        border-radius: 0.25rem;
      }
    }

    .text-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
    }

    .folder-item {
      &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.25rem 0.5rem;
        transition: background-color 0.2s;
        border-radius: 0.25rem;

        &:hover {
          background-color: #f0f0f0;
        }

        &--hovered {
          background-color: #f0f0f0;
        }
      }

      &__header {
        display: flex;
        align-items: center;
        flex-grow: 1;
        cursor: pointer;
      }

      &__icon {
        margin: 0 0.5rem;
      }

      &__name {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__toggle-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25rem;
        cursor: pointer;
        flex-shrink: 0;

        &:hover {
          background-color: #fff;
          border-radius: 0.25rem;
        }
      }

      &__toggle {
        width: 0.75rem;
        height: 0.75rem;
        user-select: none;
      }

      &__children {
        list-style: none;
        padding-left: 1rem;
        margin: 0;
      }
    }
  }

  .main-content {
    flex-grow: 1;
    width: 80%;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem;
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.75rem;

      .loading-indicator,
      .menu__loading {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        color: #6c757d;
      }

      .loading-indicator span,
      .menu__loading span {
        margin-left: 0.5rem;
      }
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .desktop-icon {
    width: 6rem;
    padding: 0.5rem;
    text-align: center;
    cursor: pointer;
    user-select: none;

    span {
      display: block;
      margin-top: 8px;
      color: #333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
      width: 100%;
    }

    &:hover {
      background-color: #f0f0f0;
      border-radius: 0.25rem;
    }

    &.selected {
      background-color: #e9e9e9;
      color: #333;
      border-radius: 0.25rem;
    }

    &.folder-drop-target {
      background-color: #e9e9e9;
      border-radius: 0.25rem;
    }
  }

  .breadcrumb-container {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #f8f9fa;
    border-radius: 0.25rem;

    &__back-button {
      cursor: pointer;
      margin-right: 0.75rem;
    }

    &__breadcrumb {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      font-size: 0.9em;
      font-weight: 500;
    }

    &__separator {
      color: #6c757d;
      margin: 0 0.5rem;
      font-weight: 600;
    }

    &__link {
      color: #5e5e5e;
      font-weight: 500;
      text-decoration: none;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: #5428e0;
      }
    }

    &__item {
      display: flex;
      align-items: center;

      &--active {
        font-weight: bold;
        color: #5428e0;
      }
    }
  }

  .desktop-context-menu {
    position: fixed;
    min-width: 10rem;
    padding: 0.5rem;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    li {
      list-style: none;
      padding: 0.5rem;
      color: #333333;
      font-size: 0.95em;
      cursor: pointer;
      transition: background-color 0.2s ease, color 0.2s ease;

      &:hover {
        background-color: #f0f0f0;
        border-radius: 0.25rem;
      }
    }

    li:last-child {
      color: #5428e0;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}
