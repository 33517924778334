.bulk-clock-modal {
    &.modal-dialog {
        max-width: 600px;
    }
    .clock-in-btn {
        background-color: #00C0FF;
        color: white;
        border:0px;
    }

    .break-btn {
        background-color:#FE9700 ;
        color: white;
        border:0px;
    }

    .rc-select-disabled .rc-select-selector {
        background-color: #f3f3f3;
    }
}