.vector-map{
  .jvectormap-zoomin,
  .jvectormap-zoomout {
    cursor: pointer;
    margin-right: .5rem;
    font-size: .75rem;
    transition: all .15s ease;
    letter-spacing: .025em;
    text-transform: none;
    will-change: transform;
    line-height: 1.5;
    padding: .25rem .5rem;
    border-radius: .25rem;
    color: #fff;
    border-color: #010a63;
    background-color: #010a63;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    font-weight: 600;
    display: inline-block;
    user-select: none;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    border: 1px solid transparent;
  }
}
