.schedule-row {
	.schedule-pay-code-select.filled-select {
		.rc-select-selector {
			background-color: transparent;
			height: 20px !important;
			&,
			input {
				color: white;
			}
		}
	}
	
	.schedule-pay-code-select {
		border-radius: 5px;
		border: 1px solid transparent !important;
		padding: 0 !important;
		width: 65px;
		display: inline-block !important;
		min-width: inherit!important;
		
		&.filled-select {
			.rc-select-selector {
				border: none !important;
			}
		}
	
		&.unpaid-filled-select {
			.rc-select-selector {
				color: #00080e;
				border: none !important;
			}
		}
		.rc-select-selector{
			padding: 0 !important;
			.rc-select-selection-item {
				right: inherit;
				text-align: center;
				left: 45%;
				transform: translate(-50%, -50%);
				padding: 0px 9px !important;
			}
		}
		.rc-select-arrow {
			color: inherit!important;
			right: 3px;
			font-size: 10px;
		}
	}

	.rc-select-single:not(.rc-select-customize-input) .rc-select-selector, .form-control{
		height: 21px !important;
		padding: 0px !important;
		min-height: 21px;
		// max-width: 60px !important;
		border-radius: 0.4375rem !important;
	}
	
	.pay-code-select-dropdown {
		&.rc-select-dropdown {
			.rc-select-item {
				&.rc-select-item-option {
					&.rc-select-item-option-active,
					&:hover {
						.rc-select-item-option-content {
							color: #525f7f !important;
						}
					}
				}
			}
		}
	}	
}