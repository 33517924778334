.dashboard-task-list-scroll {
  overflow-x: hidden;
}

.dashboard-task-list-scroll::-webkit-scrollbar-thumb {
  background-color: #5026d9;
}

.dashboard-task-list-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border: 1px solid #5026d9;
}

.dashboard-task-list-scroll::-webkit-scrollbar-track {
  background-color: hsla(0, 3%, 44%, 0.75);
}

.task-list {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}