.shift-rule-form {
    &__rules {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__item {
            background-color: #f1f5f9;
            border: 2px solid #e2e8f0;
            border-radius: 4px;

            &__head {
                padding: 16px 32px;
                border-radius: 4px;
                background-color: #e2e8f0;
                display: flex;
                justify-content: space-between;
                cursor: pointer;

                &--open {
                    border-radius: 4px 4px 0px 0px;
                }
            }

            &__content {
                padding: 16px 32px;

                &__conditions {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    &__item {
                        background-color: #e2e8f0;
                        border-radius: 4px;
                        padding: 8px 16px;
                    }
                }
            }
        }
    }
}