.scheduler-copy-paste-modal{
    width: 200px !important;
    max-width: fit-content;
    
    .custom-date-picker {
        box-shadow: none;
    }

    .custom-date-picker .react-datepicker__day-name, .custom-date-picker .react-datepicker__day {
        height: 40px !important;
        width: 40px !important;
        font-size: 1rem !important;
        margin:0.2rem !important;
    }

    .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
        font-size: 1.1rem !important;
    }
}