.posts-container {
    display: flex;
    flex-direction: column;

    .send-button {
        width: 60px;
        height: 38px;
    }

    .posts-avatar {
        color: #fff;
        background-color: #adb5bd;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        border-radius: 0.375rem;
        height: 42px;
        width: 42px;
    }

    .ql-editor strong {
        font-weight: 900;
    }

    .ql-snow .ql-tooltip::before {
        content: none !important;
    }

    .ql-tooltip.ql-editing .ql-action::before {
        content: none !important;
    }

    .ql-snow .ql-tooltip[data-mode="link"]::before {
        content: none !important;
    }

    .ql-tooltip .ql-action::before {
        content: none !important;
    }

    .ql-snow,
    .ql-tooltip.ql-editing input[type="text"] {
        ::-webkit-input-placeholder {
            color: #fff;
        }

        :-moz-placeholder {
            color: #fff;
            opacity: 1;
        }

        ::-moz-placeholder {
            color: #fff;
            opacity: 1;
        }

        :-ms-input-placeholder {
            color: #fff;
        }
    }

    .textarea-emoji-control {
        cursor: pointer;
    }

    .posts-message-card {
        border: 1px solid rgb(247, 242, 242);
        border-radius: 6px;
        overflow-x: hidden;
        max-width: 100%; 
    }

    .posts-messages-div {
        width: 90%;
        max-width: 90%;
    }

    .posts-message-card-header::before {
        content: "";
        position: absolute;
        top: 6px;
        left: 40px;
        border: solid 15px transparent;
        border-right-color: rgb(248, 243, 243);
        z-index: 1000;
    }

    .posts-message-card-header {
        display: flex;
        width: 100%;
        padding: 10px;
        justify-content: space-between;
        background-color: rgb(248, 243, 243);
    }

    .posts-message-card-body {
        padding: 8px 15px;

        .posts-content {
            img {
                border-radius: 0.375rem;
                max-width: 60%;
                height: auto;
            }
        }

        iframe.ql-video {
            border-radius: 0.375rem;
            width: 755px;
            height: 424px;
            max-width: 100%;
            max-height: calc((100vw - 40px) / (16 / 9));
        }
    }

    .ql-toolbar.ql-snow {
        border: 1px solid rgb(233, 229, 229) !important;
    }

    .ql-container.ql-snow {
        border: 1px solid rgb(233, 229, 229) !important;
        padding: 0 5px;
        height: 150px;
    }

    .ql-editor {
        border: none !important;
    }

    .textarea-emoji-control {
        margin-right: 15px;
    }

    #textarea-emoji,
    #tab-panel {
        top: 0px;
        right: 50px;
        margin-top: 5px;
        display: flex;
        flex-direction: row-reverse;
        gap: 3px;
    }

    .message-list-container {
        height: calc(100% - 256px);
        overflow-y: auto;
    }

    .posts-messages-div,
    p {
        font-size: 14px;
        color: black;
    }

    .posts-messages-div,
    strong {
        font-weight: 900;
    }

    // POSTS REPLY STYLES

    .posts-reply-content {
        word-break: break-word; 
        white-space: pre-wrap; 
        overflow: hidden; 
        max-width: 100%;

        .posts-messages-div {
            img {
                border-radius: 0.375rem;
                max-width: 50%;
                height: auto;
            }
        }
    }

    .posts-reply {
        overflow-x: hidden; 
        max-width: 90%;

        .ql-container {
            height: 80px;
        }

        .ql-toolbar {
            height: 30px;
            display: flex;
            align-items: center;
            padding: 0px 3px !important;
        }
    }

    .posts-reply p {
        max-width: 90%;
    }

    .post-reply-scroll {
        max-height: 256px;
        overflow-y: auto;
    }

    // scrollbar styles
    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
        padding: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #aaa;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #888;
    }
}

#emoji-close-div {
    z-index: -3 !important;
} 
