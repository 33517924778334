.timesheet-table {
    .custom-control.custom-checkbox {
        width: 16px;
        min-height: auto;
    }


    .table {
        tr {
            &.date-seperator {
                td {
                    border-top: 2.5px dashed #dfdfdf;
                }
            }

            &.inside-date {
                height: 30px !important;

                td {
                    padding-top: 0px !important;
                }
            }
        }

        th,
        td {
            padding: 0.5rem 0.5rem !important;
            border-top: 0px;
        }

        tr {
            &.locked {
                background-color: #f6f9fc;
            }

            &.out-of-range {
                background-color: #f6f9fc;
            }

            &:hover,
            &.paid {
                color: #525f7f;
                background-color: #fff;
            }

            &.has-error {
                background-color: #ffe4e6;
            }
        }

        .input-column {
            width: 116px;
        }

        .time-input-column {
            width: 80px;
        }

        .time-input {
            min-width: 60px;
            border-radius: .4375rem !important;
            border-style: solid !important;
            max-width: 60px;
        }

        .money-input {
            max-width: 65px;
            font-size: 11px;
        }

        .select-input {
            min-width: 90px;
            width: auto;
        }

        .group-input {
            min-width: 200px;
        }

        .time-input {
            &.filled {
                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: white;
                    opacity: 1;
                    /* Firefox */
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: white;
                }

                &::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: white;
                }
            }
        }

        .date-column {
            width: 150px;
        }

        .form-control,
        .currency-input,
        .time-input,
        .rc-select .rc-select-selector {
            padding: 0.2rem !important;
            height: auto;
            min-height: auto;
            // border-radius: 0px;
            // border-style: dashed;
        }

        .currency-input,
        .time-input {
            text-align: center;
        }

        .rc-select-arrow {
            right: 10px;
        }

        .rc-select-selector {
            // border-style: dashed !important;
            text-align: left;
        }
    }

    .timesheet-table-footer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .bulk-actions {
            grid-column: 2;
        }

        .pagination-container {
            grid-column: 3;

            .card-footer {
                border: none;
            }
        }
    }
}

.other-duration-list-tooltip {
    .tooltip-inner {
        min-width: 200px;
        max-width: none;
        background-color: white;
        padding: 1rem 2rem;
        color: #525f7f;
        box-shadow: 0px 15px 35px #32325D33;   
    }

    .start {
        color: rgb(0, 153, 139);
        background-color: rgba(0, 153, 139, 0.1);
        border-width: 0;
        margin-right: .5rem;
    }

    .end {
        color: rgb(255, 97, 97);
        background-color: rgba(255, 97, 97, 0.1);
        border-width: 0;
    }

    .start,
    .end {
        padding: 0.2rem;
        min-width: 60px;
        border-radius: .4375rem;
        border-style: solid;
        max-width: 60px;
    }
}