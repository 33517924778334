.currency-input {
	// @apply max-w-full flex-auto m-0 inline-block w-full min-w-0 box-border tabular-nums	relative  bg-white list-none;

	font-feature-settings: "tnum", "tnum";
	padding: 7px 11px;
	color: #384d5b;
	border: 1px solid #d9d9d9;
	border-radius: 3px;
	font-size: 1rem;
	transition: all 0.3s;

	&:focus {
		border-color: #010a63;
		// box-shadow: 0 0 0 2px rgba(0, 192, 255, 0.2);
		border-right-width: 1px;
		outline: 0;
	}
}
