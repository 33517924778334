.custom-time-input {
	min-width: 100px;
	width: 100%;

	.ant-input {
		border: none;
	}

	.message {
		color: #f52e38;
		font-size: 1.15rem;
	}
}
