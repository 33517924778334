.payroll-export-modal {
    &__navigation {
        &__list {
            display: flex;
            border-top-width: 1px;
            border-bottom-width: 1px;
            border-radius: 0.375rem;
            border: 1px solid #D1D5DB;
            list-style: none;
            padding-left: 0px;

            &__item {
                position: relative;
                width: 100%;

                &--completed &__content {
                    &__number {
                        background-color: #4F46E5;
                    }

                    &__name {
                        color: #4F46E5;
                    }
                }

                &--current &__content {
                    &__number {
                        border-color: #4F46E5;
                        color: #4F46E5;
                    }

                    &__name {
                        color: #4F46E5;
                    }
                }

                &--future &__content {
                    &__number {
                        border-color: #6B7280;
                    }

                    &__name {
                        color: #6B7280;
                    }
                }

                &__content {
                    display: flex;
                    align-items: center;
                    padding: 1rem 1.5rem;
                    font-weight: 500;


                    &__number {
                        display: flex;
                        height: 2.5rem;
                        width: 2.5rem;
                        flex-shrink: 0;
                        align-items: center;
                        justify-content: center;
                        border-radius: 9999px;
                        border: 2px solid #525f7f;
                        color: #525f7f;

                        svg {
                            color: #ffffff;
                            width: 1.5rem;
                            height: 1.5rem;
                        }
                    }

                    &__name {
                        margin-left: 1rem;
                        font-weight: bold;
                        color: #525f7f;
                    }
                }

                &__arrow {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: block;
                    height: 100%;
                    width: 1.25rem;

                    svg {
                        height: 100%;
                        width: 100%;
                        color: #D1D5DB;
                    }
                }
            }
        }
    }

    &__statistics {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        margin-bottom: 1rem;

        &__item {
            padding: 1.5rem;
            background-color: rgb(243, 244, 246);
            border-radius: 0.5rem;
            overflow: hidden;
            --tw-shadow: 0 1px 3px 0 rgb(0, 0, 0, 0.1), 0 1px 2px -1px rgb(0, 0, 0, 0.1);
            --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
            box-shadow: var(--tw-ring-offset-shadow, 0, 0, #0000), var(--tw-ring-shadow, 0, 0, #0000), var(--tw-shadow);

            &__title {
                color: rgb(107, 114, 128);
                font-weight: 500;
                font-size: 14px;
                line-height: 1.25rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &__value {
                --tw-text-opacity: 1;
                color: rgb(17, 24, 39);
                letter-spacing: -0.025em;
                font-weight: 600;
                font-size: 30px;
                line-height: 2.25rem;
                margin-top: 0.25rem;
            }
        }
    }

    &__comparison {
        display: grid;
        grid-template-columns: 1fr 0.4fr 1fr;
        max-height: 400px;
        overflow-y: auto;
        margin: 20px 0px;

        &__header {
            font-size: 18px;

            &:nth-child(1) {
                text-align: right;
                padding-right: 16px;
            }

            &:nth-child(3) {
                text-align: left;
                padding-left: 16px;
            }
        }

        &__stat {
            padding-top: 16px;
            padding-bottom: 16px;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 16px;
                bottom: 16px;
                border-right: 2px solid #cbd5e1;
            }

            &--past,
            &--current {
                display: flex;
                flex-direction: column;
            }

            &--past {
                align-items: end;
                padding-right: 16px;

                &::after {
                    right: 0px;
                }
            }

            &--current {
                align-items: start;
                padding-left: 16px;

                &::after {
                    left: 0px;
                }
            }

            &__title {
                font-weight: 600;
                font-size: 20px;
                line-height: 1;
                margin-bottom: 0;
                margin-top: 6px;
            }

            &__payment,
            &__duration {
                font-size: 14px;
                line-height: 1;
                padding: 4px 8px;
                border-radius: 8px;
                font-weight: 300;
            }

            &__payment {
                background-color: #f1f5f9;
                color: #475569;
            }

            &__duration {
                margin-right: 16px;
                position: relative;
                background-color: #475569;
                color: #f1f5f9;

                &::after {
                    content: "";
                    position: absolute;
                    top: 4px;
                    bottom: 4px;
                    right: -8px;
                    border-right: 2px solid #cbd5e1;
                }
            }
        }

        &__diff {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;

            &__icon {
                font-size: 14px;

                &--up {
                    color: red;
                }

                &--down {
                    color: green;
                }
            }

            &__value {
                display: inline-block;
                font-size: 14px;
                line-height: 1;
                padding: 4px 8px;
                border-radius: 4px;
                font-weight: 300;
                margin: 0px 8px;
            }

            &__payment &__value {
                background-color: #f1f5f9;
                color: #475569;
            }

            &__duration &__value {
                background-color: #475569;
                color: #f1f5f9;
            }
        }
    }
}