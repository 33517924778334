.sidebar {
    .item, a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.675rem 1.5rem;
        color: #000000b5;
        font-size:1rem;
        font-weight: 500;
        white-space: nowrap;

        .item-icon, .toggle {
            margin-right: 0.5rem;
        }

        &.active {
            color: #010a63;
        }
    }

    .nav-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        font-size:1rem;
        color: #000000b5;
        padding: 0.675rem 1.5rem;
    }

    .link, .nav-group {
        .link {
            a, .nav-group {
                padding-left: 3rem;
            }

            .link, .nav-group {
                a, .nav-group {
                    padding-left: 4.5rem;
                }
            }
        }
    }
}

.navbar-bottom-background {
    z-index: -1;
}
