.add-employee{
    display: flex;
    height: 100%;

    .card{
        margin-bottom: auto;

        .add-employee-step1{
            width: 500px;
        
            .button{
               height: 128px;
               width: 158px;
               border-radius: 39px;
            }
        
            .description{
              height: 128px;
              width: 158px;
            }
        }

        .react-tel-input .form-control{
          display: block;
          width: 100%;
          height: calc(1.5em + 1.25rem + 7px);
          padding: 0.625rem 3.5rem;
          font-size:1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #8898aa;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #dee2e6;
          border-radius: 0.25rem;
          box-shadow: 0 3px 2px rgba(233, 236, 239 , 0.005);
          transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);

            &:focus{
              border-color: #8965e0;
            }
        }

        .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle{
          box-shadow: 1px 2px 2px #32325dbd;
          border-color: #32325dbd;
          background-color: white;
        }
        
        @media  screen and ( max-width:768px) {
            .add-employee-step1{
              width: 400px;
        
              .button{
                height: 200px;
                width: 300px;
                border-radius: 39px;
                
                img{
                    height: 190px;
                }
             }
         
             .description{
               width: 300px;
             }
            }
          
          }
          
        @media screen and (max-width: 500px){
            .add-employee-step1{
              width: 340px;
        
              .button{
                height: 200px;
                width: 300px;
                border-radius: 39px;
               
             }
         
             .description{
               width: 300px;
             }
            }
        }
    }

    @media  screen and ( max-width:768px) {
        .card{
            margin-bottom: auto;
        }
    }
}