.pay-code-select {
    border-radius: 0.25rem;

    height: 24px;

    .rc-select-selection-placeholder {
        color: #525f7f;
        text-align: center;
    }

    &.rc-select-selector {
        height: 24px !important;
    }
}

.pay-code-select.filled-select {
    .rc-select-selector {
        background-color: transparent;

        &,
        input {
            color: white;
        }
    }
}

.pay-code-select {
    border-radius: 5px;
    // border: 1px solid transparent !important;
    padding: 0 !important;
    // width: 65px;
    display: inline-block !important;
    min-width: inherit !important;

    &.filled-select {
        .rc-select-selector {
            border: none !important;
        }
    }

    &.unpaid-filled-select {
        .rc-select-selector {
            color: #00080e;
            border: none !important;
        }
    }

    .rc-select-selector {
        padding: 0 !important;
        height: 24px !important;

        .rc-select-selection-item {
            top: 50% !important;
            right: inherit;
            text-align: center;
            left: 50%;
            transform: translate(-50%, -50%) !important;
        }
    }

    .rc-select-arrow {
        color: inherit !important;
        right: 3px;
        font-size: 10px;
    }
}

.pay-code-select-dropdown {
    &.rc-select-dropdown {
        .rc-select-item {
            &.rc-select-item-option {

                &.rc-select-item-option-active,
                &:hover {
                    .rc-select-item-option-content {
                        color: #525f7f !important;
                    }
                }
            }
        }
    }
}