.invalid-date-time-input {
    & .form-control, &.form-control {
        border: 1px solid #fd5c70 !important;
    }
}

.react-datepicker-popper{
    z-index: 9999;
}

.react-datepicker-wrapper {
    display: inline-flex;
    width: 100%;
}

.custom-date-picker {
    padding: 20px;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.375rem;
    border: none;
    z-index: 10;

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker__header {
        background: #ffffff;
        border-bottom: 0px;

        &, .react-datepicker__current-month {
            font-weight: inherit;
        }
    }


    .react-datepicker__year--container{
        width: 220px;
    }

    .react-datepicker__year-wrapper{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            max-width: none;
    }

    .react-datepicker__year-text {
        padding: 4px 0px;
        margin: 1px;

        &:hover {
            background-color: #eee;
            color:#525f7f
        }

        &.react-datepicker__year-text--keyboard-selected {
            background-color: #5428e0 !important;
        }

        &.react-datepicker__year-text--today {
            font-weight: inherit;
        }

        &.react-datepicker__year--selected {
            color: #ffffff;
            background-color: #5428e0;
        }
    }

    .react-datepicker__year-text--selected{
        color: #ffffff;
        background-color: #5428e0;
    }


    .react-datepicker__month {
        margin: 0px;
    }

    .react-datepicker__month-text {
        border-radius: 100%;

        &:hover {
            background-color: #eee;
        }

        &.react-datepicker__month-text--keyboard-selected {
            background-color: inherit;
        }

        &.react-datepicker__month-text--today {
            font-weight: inherit;
        }

        &.react-datepicker__month-text--selected {
            color: #ffffff;
            background-color: #5428e0;
        }
    }
    
    .react-datepicker__day {
        border-radius: 100%;

        &.react-datepicker__day--disabled {
            color: #adb5bd;
        }

        &.react-datepicker__day--keyboard-selected {
            background-color: inherit;
        }

        &.react-datepicker__day--today {
            font-weight: inherit;
        }

        &.react-datepicker__day--outside-month {
            color: #adb5bd;
            font-weight: 300;
        }

        &.react-datepicker__day--selected,
        &.react-datepicker__day--in-range,
        &.react-datepicker__day--in-selecting-range,
        &.react-datepicker__day--selecting-range-start {
            color: #ffffff;
            background-color: #5428e0;
        }
    }

    .react-datepicker__navigation {
        top: 28px;
    }

    .react-datepicker__navigation--previous {
        left: 28px;
    }

    .react-datepicker__navigation--next {
        right: 28px;
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
        font-size: 1rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        width: 36px;
    }

    .react-datepicker__month-text {
        font-size: 1.23rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        width: 56px;
    }

    .react-datepicker__header,
    .react-datepicker__current-month,
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__month-text {
        color: #525f7f;
    }
}

.react-datepicker__day--excluded {
    background-color: #11ceef3b !important;
    color: #fff !important;
}

