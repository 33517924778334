.rc-select {
    display: block;
    width: 100%;

    &,
    input {
        cursor: text;
        color: #8898aa;
    }

    .rc-select-selector {
        // copy of .form-control class
        padding: 0.625rem 0.75rem !important;
        font-size:1rem;
        transition: all 0.15s ease-in-out;
        min-height: calc(1.5em + 1.25rem + 5px);
        font-weight: 400;
        line-height: 1.5;
        color: #8898aa;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #dee2e6 !important;
        border-radius: 0.25rem;
        box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);

        .rc-select-selection-search-input {
            background: transparent !important;
        }

        .rc-select-selection-placeholder,
        .rc-select-selection-item {
            width: 100%;
            left: 12px;
            right: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .rc-select-selection-placeholder {
            position: absolute;
        }
    }

    .rc-select-arrow {
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
    }

    .rc-select-selector .rc-select-selection-placeholder,
    &:not(.rc-select-multiple) .rc-select-selector .rc-select-selection-item,
    .rc-select-arrow,
    .rc-select-clear {
        top: 50%;
        transform: translateY(-50%);
    }

    .rc-select-arrow,
    .rc-select-clear {
        cursor: pointer;
    }

    .rc-select-arrow {
        right: 20px;
    }

    .rc-select-clear {
        // right: 40px;
    }

    &.rc-select-multiple {
        .rc-select-selection-overflow {
            gap: 0.1rem 0;

            .rc-select-selection-overflow-item {
                .rc-select-selection-item {
                    padding: 0 0.4em;
                    font-size: 90%;
                    text-align: center;
                    white-space: nowrap;
                    vertical-align: baseline;
                    border-radius: 0.25rem;
                    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                    color: #8898aa;
                    background-color: transparent;
                    border: 1px solid #dee2e6;
                    display: inline-block;

                    .rc-select-selection-item-content {
                        margin-right: 0.25rem;
                    }

                    .rc-select-selection-item-remove {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &.rc-select-disabled {
        .rc-select-selector {
            opacity: 1;
            background-color: #e9ecef ;
        }
    }
}

.rc-select-dropdown {
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #525f7f;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.15);
    border-radius: 0.4375rem;
    box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
        0 15px 35px rgba(50, 50, 93, 0.15),
        0 5px 15px rgba(0, 0, 0, 0.1);
    min-height: auto;
    z-index: 2000;

    .rc-select-item {
        cursor: pointer;

        &.rc-select-item-option {
            &.rc-select-item-option-active {
                background-color: #F5F7FA !important;
            }

            &.rc-select-item-option-selected {
                background-color: #F0F2F5 !important;
            }

            &.rc-select-item-option-active {
                background: transparent;
            }
        }
    }
}

.invalid-select {
    .rc-select-selector {
        border: 1px solid #fd5c70 !important;
    }
}