.manager-dashboard {
    height: 50%;

    .dashboard-timeclock-team {
        height: 100%;

        .item {
            display: flex;
            align-items: center;

            .icon {
                padding: 3px;
                font-size: 0.7rem;
                border-radius: 50%;
                font-weight: bold;
                text-align: center;
                margin-right: 7px;
                height: 15px;
                width: 15px;
            }
        }

        .form-control {
            border: 0;
            box-shadow: none;
            height: 55px;
        }

        .text-on-break {
            color: #f99b16;
            font-weight: bolder;
        }

        .text-clocked-in {
            color: #11cdef;
            font-weight: bolder;
        }

        .text-clocked-out {
            color: red;
            font-weight: bolder;
        }
    }

    .team-list-scroll {
        overflow-x: hidden;

        &::-webkit-scrollbar-thumb {
            background-color: #a855f7;
        }

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            border: 1px solid #a855f7;
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(116, 109, 109, 0.75);
        }
    }

    .layout {
        .react-grid-item {
            background-color: transparent;
            box-shadow: none;

            .react-resizable-handle {
                z-index: 999;
                background-color: rgba($color: #a855f7, $alpha: 0.3);

                &.react-resizable-handle-n,
                &.react-resizable-handle-s,
                &.react-resizable-handle-e,
                &.react-resizable-handle-w {
                    background-color: rgba($color: #a855f7, $alpha: 0.3);
                    border-radius: 100%;
                }

                &:hover {
                    background-color: rgba($color: #a855f7, $alpha: 0.5);
                }

                &:active {
                    background-color: rgba($color: #a855f7, $alpha: 0.7);
                }
            }
        }

        .edit-controls {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding: 2px 4px;
        
            .handle {
                cursor: move;
                user-select: none;
                z-index: 999;
                font-weight: bold;
                font-size: 1.2rem;
            }
        
            .edit-controls-buttons {
                margin-left: auto;
                display: flex;
                gap: 8px;
                z-index: 999;
        
                span {
                    cursor: pointer;
                    font-weight: bold;
        
                    &:hover {
                        color: #a855f7;
                    }
                }
            }
        }
        
        .react-grid-placeholder {
            background-color: rgba($color: #a855f7, $alpha: 0.5) !important;
            border: 2px dashed #a855f7;
            z-index: 1;
            border-radius: 4px;
        }
    }

    @media (max-width: 1366px) {
        height: 60%;
    }

    @media (max-width: 630px) {
        .dashboard-timeclock-team {
            margin-bottom: 26px;
        }
    }

    @media screen and (orientation: landscape) and (max-width: 916px) {
        height: 100%;
    }
}

