.scheduler-copy-paste-modal{
    min-width: 700px;
    max-width: fit-content;
    
    .custom-date-picker {
        box-shadow: none;
    }

    .custom-date-picker .react-datepicker__day-name, .custom-date-picker .react-datepicker__day {
        height: 45px;
        width:45px;
        font-size: 1.1rem;
        margin:0.37rem;
    }

    .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
        font-size: 1.2rem;
    }
}

.scheduler-drawer {
    min-width: 300px;
    max-width: 800px;

    .shifts-table {
        td {
            padding: 0.5rem 0.5rem !important;
            vertical-align: middle;
            .rc-select {
                width: 80px !important;
                text-align: start;
                border-radius: 0.4375rem !important;
                .rc-select-clear{
                    right: 10px;
                }
                &.w-100 {
                    width: 100% !important;
                    .rc-select-selector {
                        max-width: 100% !important;
                    }
                }
            }
            .rc-select-single:not(.rc-select-customize-input) .rc-select-selector, .form-control{
                height: 20px !important;
                padding: 0px !important;
                min-height: 20px;
                max-width: 80px;
                border-radius: 0.4375rem !important;
            }
            input[type="text"] {
                width: 60px;
                border-radius: 0.4375rem !important;
                padding: 0.5rem !important;
            }
            .current-time-input {
                min-width: 80px !important;
                width: 80px !important;
                text-align: center;
                border-radius: 0.4375rem !important;
            }
        }
    }

    .pay-code-select .rc-select-selection-placeholder {
        text-align: start;
    }
    .time-input-progress{
        background-color: white;
        border-top: 2px dashed rgb(233, 236, 239);
        border-right: 2px dashed rgb(233, 236, 239);
        border-left: 2px dashed rgb(233, 236, 239);
        &::after{
            content: '>';
            rotate: 90deg;
            position: relative;
            right: -5px;
            color: #999c9f;
            bottom: -6px;
            font-weight: bold;
            background-color: #fff;
        }
        &::before {
            content: '>';
            rotate: 90deg;
            position: relative;
            left: -5px;
            color: #999c9f;
            bottom: -6px;
            font-weight: bold;
            background-color: #fff;
        }
    }
}