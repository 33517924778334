.analytics-table {
    $cell-width: 150px;
    background: #f1f3f6;

    .inner-container {
        overflow-x: auto;
        margin-left: $cell-width;
        margin-right: $cell-width;

        table {
            table-layout: fixed;

            thead {
                tr {
                    th {
                        background-color: transparent;
                        color: #000000b5;

                        &:first-of-type {
                            box-shadow: 8px 0px 4px -4px rgba(0,0,0,0.15);
                        }
                    }
                }
            }

            th, td {
                div {
                    padding: 1rem 1rem;
                    margin: 0rem 1rem;
                }

                padding: 0;
                background-color: white;
                font-size: 0.9rem;
                width: $cell-width;
                font-weight: 900;

                &:first-of-type {
                    position: absolute;
                    left: 0;
                }

                &:last-of-type {
                    font-weight: 900;
                    position: absolute;
                    right: 0;
                }

                &:nth-last-of-type(2) {
                    box-shadow: inset -8px 0px 4px -4px rgba(0,0,0,0.15);
                }
            }

            td {
                color: #8898aa;

                &:first-of-type {
                    color: #000000b5;

                    &:before {
                        content: "";
                        box-shadow: inset 8px 0px 4px -4px rgba(0,0,0,0.15);
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: -1px;
                        width: 10px;
                        transform: translateX(100%);
                    }
                }

                &:last-of-type {
                    color: #000000b5;
                    background-color: transparent;
                }
            }

            tbody {
                tr {
                    &:not(:last-of-type) {
                        td {
                            div {
                                border-bottom: solid 1px #CECECE;
                            }
                        }
                    }
                }
            }

            $border-radius: .25rem;
            tr {
                &:first-of-type {
                    border-top-left-radius: $border-radius;
                    border-top-right-radius: $border-radius;

                    th {
                        &:first-of-type {
                            border-top-left-radius: $border-radius;
                        }

                        &:last-of-type {
                            border-top-right-radius: $border-radius;
                        }
                    }
                }

                &:last-of-type {
                    border-bottom-left-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;

                    td {
                        &:first-of-type {
                            border-bottom-left-radius: $border-radius;
                        }

                        &:last-of-type {
                            border-bottom-right-radius: $border-radius;
                        }
                    }
                }
            }
        }
    }
}
