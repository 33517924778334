.slider {
  height: 344px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  gap: 10px;
}

.slide {
  height: 100%;
  max-height: 344px;
  min-width: 190px;
  width: 190px;
  background-color: lightblue;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.slide span {
  font-weight: bold;
  font-size: 40px;
}


