.guarantee-rule {
	display: grid;
	grid-template-columns: 3fr 2fr auto;
    margin-bottom: 8px;

	.form-control-sm{
		width: 60px;
	}

	
	.rc-select {
		width: 130px;

		.rc-select-selector {
			min-height: 31px !important;
			padding: 0px !important;
			border: 1px solid #010a63 !important;
		}
	}
}