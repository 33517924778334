.password-input {
    &.input-group{
        .form-control{
            border-right:0;
        }
    }
    
    .toggle {
        box-shadow: none;
        padding-left: 16px;
        border: 1px solid #dee2e6;
        padding-right: 16px;
        border-left: none;
        border-radius: 0px 0.25rem 0.25rem 0px;
    }
}
