.days-list-container {
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #f5f5f5;

    .day-list-item {
        padding: 15px;
        margin-bottom: 10px;
        border-radius: 5px;
        background-color: #e7e7e7;
        display: flex;
        gap: 8;
    }
}