.main-content {
    position: unset !important;
}

.absolute {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1050;
}

.background-launch{
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1050;
}


.position-top{
    position: sticky;
    top: 0;
    z-index: 10;
}



/* .drawer-absolute-payoff{
    position: absolute;
    right: 0;
    z-index: 100;
    bottom: 0;
} */

.bg-gradient-launch {
    background: linear-gradient(-87deg, #0f3c76 0, #5428e0 100%) !important;
}
  

.scroll-x{
    overflow-x: hidden;
}

.step-wizard {
   width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.step-wizard-list{
    background: #fff;
    width: 100%;
    color: #333;
    list-style-type: none;
    border-radius: 10px;
    padding: 20px 1px;
    position: relative;
    z-index: 10;
    display: grid;
    grid-template-columns: repeat(4,1fr);
}

.step-wizard-list-launch{
    color: #fff;
    list-style-type: none;
    border-radius: 10px;
    padding: 20px 1px;
    position: relative;
    z-index: 10;
    display: grid;
    grid-template-columns: repeat(8,1fr);
    padding-top: 30px;
}




.step-wizard-item{
    padding: 0 20px;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive:1;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    width: 100% !important;
    height: unset !important;
    margin-bottom: 10px

}
.step-wizard-item + .step-wizard-item:after{
    content: "";
    position: absolute;
    left: 0;
    top: 13px;
    background-image: linear-gradient(to right, rgb(255, 255, 255) 33%, rgba(118, 114, 114, 0.245) 0%);
    background-position: bottom;
    background-size: 14px 3px;
    background-repeat: repeat-x;
    width: 100%;
    height: 2px;
    transform: translateX(-50%);
    z-index: -10;
}


.progress-count{
    height: 28px;   width:28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
    margin: 0 auto;
    position: relative;
    z-index:10;
    color: white;
}
.progress-count:after{
    content: "";
    height: 40px;
    width: 40px;
    /* background: #21d4fd; */
    background-color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -10;
}

.progress-label{
    font-size: 1rem;
    font-weight: 400;
    margin-top: 10px;
}

.current-item{
    height:10px;
    width:10px;
}

.current-item .progress-count:after{

    color: white;
    border: 1px solid rgb(147, 139, 139);
}
.current-item .progress-count{
    color: rgb(147, 139, 139);
}

.on-item{
    height:10px;
    width:10px;
}


.on-item .progress-count:after{
    background: rgb(245, 154, 26);
    border: 1px solid rgb(147, 139, 139);
}
.on-item .progress-count{
    color: rgb(251, 246, 246);
}

@media screen and (max-width: 425px) {
    .step-wizard-list{
        grid-template-columns: repeat(2,1fr);
        }
        .step-wizard-list li:nth-child(3):after {
        background: none;
    }
}

@media screen and (max-width: 500px) {
    .drawer-absolute{
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh !important;
    }

}


@media screen and (max-width: 768px) {
    .step-wizard-list-launch{
    grid-template-columns: repeat(4,1fr);
    }
    .step-wizard-list-launch li:nth-child(5):after {
    background: none;
}

.step-wizard-item label{
    font-size: 1rem;
}


}



@media screen and (max-width: 883px) {
    .step-wizard-list-launch{
        grid-template-columns: repeat(4,1fr);
        }



        .step-wizard-margin{
            margin-bottom: 18px;
        }

        .progress-label{
            font-size: 1rem;
        }
        .step-wizard-list-launch li:nth-child(5):after {
        background: none;
    }


}

@media screen and (max-width: 1000px) {
    .dnone{
        display: none !important;
    }
}

