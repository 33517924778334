.email-ftp-tooltip{
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before{
        border-left-color: white;
        border-right-color: white;
        background: transparent;
    }
    
    .tooltip{
        &.show{
            opacity:1;
        }

        .tooltip-inner {
            min-width: 300px;
            max-width: none;
            background-color: white;
            padding: 1rem 2rem;
            box-shadow: 0px 15px 35px #32325D33;   
        }
    
        .ftp-table{
            tr{
                border-color: #d1d1d1;
                
                th{
                    color: #010a63;
                    text-align: start;
                    padding: 0.5rem;
                    font-size: 12px;
                    width: 50%;
                }
                td{
                    text-align: start;
                    color:white;
                    text-transform: uppercase;
                    padding-left: 7px;
                    margin: 3px 1px;
                    font-size: 12px;
                    font-weight: 600;
                    background-color: #010a63;
                    width: 50%;
                    border-radius: 5px;
                }
            }
        }
    }
}