.schedule-shifts-table {
    td {
        padding: 0.5rem 0.5rem !important;
        vertical-align: middle;
        .rc-select {
            width: 60px !important;
            text-align: start;
            border-radius: 0.4375rem !important;
            .rc-select-clear{
                right: 10px;
            }
            &.w-100 {
                width: 100% !important;
                .rc-select-selector {
                    max-width: 100% !important;
                }
            }
        }
        .rc-select-single:not(.rc-select-customize-input) .rc-select-selector, .form-control{
            height: 24px !important;
            padding: 0px !important;
            min-height: 24px;
            // max-width: 60px !important;
            border-radius: 0.4375rem !important;
        }

        .rc-select .rc-select-selector .rc-select-selection-placeholder, 
        .rc-select:not(.rc-select-multiple) .rc-select-selection-item, 
        .rc-select .rc-select-arrow, .rc-select .rc-select-clear {
            width: 70px;
        }
        // input[type="text"] {
        //     width: 60px;
        //     border-radius: 0.4375rem !important;
        //     padding: 0.5rem !important;
        // }
        .current-time-input, .duration-input {
            min-width: 60px;
            border-radius: .4375rem !important;
            border-style: solid !important;
            max-width: 60px;
            padding: 0.2rem!important;
            height: 24px;
            min-height: 24px;
            text-align: center;
        }
    }
}