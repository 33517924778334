.add-absence-modal {
    max-height: 800px;
    height: 800px;
    min-height: 800px;

    .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0px 20px;
    }
}