.permission-tree{
    .rc-tree .rc-tree-treenode span.rc-tree-switcher, .rc-tree .rc-tree-treenode span.rc-tree-checkbox, .rc-tree .rc-tree-treenode span.rc-tree-iconEle{
        background-image: none;
        width: 7px;
        display: inline-flex;
        align-items: center;
        line-height: 20px;
        vertical-align: 0px;
    }

    .rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper{
        display: inline-flex;
        align-items: center;
    }

    .custom-control{
       display: flex;
       align-items: center;
    }

    .role-checkbox{
        &::after{
            border-radius: 0.25rem;
            border-color: #010a63;
            background-color: #010a63;
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%274%27 viewBox=%270 0 4 4%27%3e%3cpath stroke=%27%23fff%27 d=%27M0 2h4%27/%3e%3c/svg%3e");
        }
    }
    
    .rc-tree-title{
        padding: 0 0.9375rem;
    }
}
