.calculation-group-content{
    .input-number{
        width: 55px;
    }
    
   .meal-allowance-pay-code-select{
        .form-control-sm{
            width: 60px;
        }
            
        .rc-select {
            width: 130px;
            .rc-select-selector {
                min-height: 31px !important;
                padding: 0px !important;
            }
        }
   }

   .meal-allowance-pay-code-select .rc-select-selector, .meal-allowance-pay-code-select .rc-select-selector input{
    color: #8898aa;
   }

   .rule-container {
        border: 1px dashed #ccc;
        padding: 10px;
        width: calc(100% - 30px);
   }
}