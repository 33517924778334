.schedule-config-table {
    height: fit-content;
    td {
        padding: 10px;
    }
}

.scheduler-event-daily {
    width: 400px;
    background: #fff !important;
    border-inline-start: 4px solid #5428e0 !important;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.18);
    line-height: 8px;

    .duration {
        color: #5428e0;
        font-size: 11px;
    }

    .break {
        color: #f99b16;
        font-size: 11px;
    }

    .overtime {
        color: #ad3e6d;
        font-size: 11px;
    }
}

.scheduler-event-weekly {
    width: 180px;
    background: #fff !important;
    border-inline-start: 4px solid #5428e0 !important;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.18);

    .duration {
        color: #5428e0;
        font-size: 11px;
    }

    .break {
        color: #f99b16;
        font-size: 11px;
    }

    .overtime {
        color: #ad3e6d;
        font-size: 11px;
    }
}

.scheduler-event-monthly {
    width: 160px;
    background: #fff !important;
    border-inline-start: 4px solid #5428e0 !important;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.18);

    .duration {
        color: #5428e0;
        font-size: 12px;
    }

    .break {
        color: #f99b16;
        font-size: 12px;
    }

    .overtime {
        color: #ad3e6d;
        font-size: 12px;
    }
}
