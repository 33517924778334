.timesheet-copy-data-table{
    width:100%;

   thead{
        tr{
            th{
                background-color: #f5f5f5;
                padding: 1rem 0.7rem;
                font-weight: 550;
                font-size: 12px;
            }
        }
   }

   tbody{
     tr{
        border-bottom:1px solid #f5f5f5;
        td{
            padding: 0.9rem 0.5rem;
            font-size: 11px;
            color: #212529;
        }
     }
   }
}