.drawer {
    $head-height: 70px;
    z-index: 100;
    position: relative;

    .card {
        .card-header {
            position: absolute;
            height: $head-height;
            top: 0;
            left: 0;
            right: 0;
        }

        > .content {
            margin-top: $head-height;
            max-height: calc(100% - #{$head-height});
            height: 100%;
            overflow-y: auto;

            .card-body {
                height: 100% !important;
            }
        }
    }
}
