.employee-dashboard {
    height: calc(100% - 20px);

    .scroll {
        overflow-x: hidden;
    }

    .scroll::-webkit-scrollbar-thumb {
        background-color: #5026d9;
    }

    .scroll::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        border: 1px solid #5026d9;
    }

    .scroll::-webkit-scrollbar-track {
        background-color: hsla(0, 3%, 44%, 0.75);
    }

    .layout {
        .react-grid-item {
            background-color: transparent;
            box-shadow: none;

            .react-resizable-handle {
                z-index: 999;
                background-color: rgba($color: #a855f7, $alpha: 0.3);

                &.react-resizable-handle-n,
                &.react-resizable-handle-s,
                &.react-resizable-handle-e,
                &.react-resizable-handle-w {
                    background-color: rgba($color: #a855f7, $alpha: 0.3);
                    border-radius: 100%;
                }

                &:hover {
                    background-color: rgba($color: #a855f7, $alpha: 0.5);
                }

                &:active {
                    background-color: rgba($color: #a855f7, $alpha: 0.7);
                }
            }
        }

        .edit-controls {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
    
            .handle {
                cursor: move;
                user-select: none;
                z-index: 999;
                font-weight: bold;
                font-size: 1.2rem;
            }
    
            .edit-controls-buttons {
                margin-left: auto;
                display: flex;
                gap: 8px;
                z-index: 999;
    
                span {
                    cursor: pointer;
                    font-weight: bold;
    
                    &:hover {
                        color: #a855f7;
                    }
                }
            }
        }
    
        .react-grid-placeholder {
            background-color: rgba($color: #a855f7, $alpha: 0.5) !important;
            border: 2px dashed #a855f7 !important;
            z-index: 1;
            border-radius: 4px;
        }
    }
}

@media(max-width: 991px) {
    .employee-dashboard {
        height: auto;
    }

    .requests-responsive {
        margin-left: 0 !important;
        margin: 15px 0px 15px 0px;
    }
}