.availability-weekcards-container {
    .weekdays-width {
        width: 120px;
    }

    .approve-button-style {
        font-size: 22px;
        color: rgb(22, 148, 22);
    }

    .pending-button-style {
        text-align: center;
        color: orange;
    }
}

@media (max-width: 1750px) {
    .responsive-availability-inputs {
        display: flex;
        flex-direction: column-reverse;
        gap: 10px
    }

    .availability-reason-input {
        width: calc(100% - 37px)
    }
}

@media (max-width: 1505px) {
    .responsive-availabiliy-container {
        width: 100%;
        justify-content: center;
        flex-direction: column;
        
        .responsive-availability-inputs {
            margin-top: 12px;
        }
    }
}