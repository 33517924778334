.schedule-time-input-progress {
    background-color: white;
    border-top: 2px dashed rgb(233, 236, 239);
    border-right: 2px dashed rgb(233, 236, 239);
    border-left: 2px dashed rgb(233, 236, 239);
    &::after{
        content: '>';
        rotate: 90deg;
        position: relative;
        right: -5px;
        color: #999c9f;
        bottom: -6px;
        font-weight: bold;
        background-color: #fff;
    }
    &::before {
        content: '>';
        rotate: 90deg;
        position: relative;
        left: -5px;
        color: #999c9f;
        bottom: -6px;
        font-weight: bold;
        background-color: #fff;
    }
}