.overview{
    .avatar-xl{
        width: 140px;
        height: 140px;
        cursor: pointer;
    }

    .avatar-image{
        label{
            color: transparent;
            height: 140px;
            width: 140px;
            cursor:pointer;
            position: absolute;
            top:calc(15% - 1px);
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            margin-left: auto;
            margin-right: auto;
            border-radius: 50%;
            &:hover{
               background-color: rgba(94, 114, 228, 0.5);
            }
        }

        .form-control-file, .form-control-range{
            display: none;
        }
    }

    .user-avatar-image{
        label{
            color: transparent;
            height: 140px;
            width: 140px;
            cursor:pointer;
            position: absolute;
            top:calc(13% - 2px);
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            margin-left: auto;
            margin-right: auto;
            border-radius: 50%;
            &:hover{
               background-color: rgba(94, 114, 228, 0.5);
            }
        }

        .form-control-file, .form-control-range{
            display: none;
        }
    }

    .delete-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: white;
        transform: translate(50%, 20%);
        -ms-transform: translate(50%, 40%);
        font-size: 1.15rem;
        padding: 4px 9px;
        cursor: pointer;
        text-align: center;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.15)
    }
      
}