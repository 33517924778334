.view-request-drawer {
    .shifts-table td {
        padding: 7px !important;
        vertical-align: middle;
        .rc-select {
            width: 80px !important;
            text-align: start;
            .rc-select-clear{
                right: 10px;
            }
        }
        .rc-select-single:not(.rc-select-customize-input) .rc-select-selector, .form-control{
            height: 20px !important;
            padding: 5px !important;
            min-height: 20px;
            max-width: 80px;
        }
        input[type="number" i] {
            width: 60px;
        }
        .current-time-input {
            min-width: 80px !important;
            width: 80px !important;
            text-align: center;
        }
    }

    .pay-code-select .rc-select-selection-placeholder {
        text-align: start;
    }

    .reason-display {
        background: #ededed;
        border-radius: 5px;
        padding: 5px 15px;
    }
}