.location-search{
    .input-group{
        border: 1px solid #dee2e6;
    }

    li{
        background-color: rgb(250, 250, 250);
        border-radius: 5px;
        margin: 1px;

      &:hover{
        background-color: whitesmoke;
        border-radius: 5px;
        margin: 1px;
        color: gray
      }
    }
}