.team-members-wrapper {
    .time-clock-team {
        .select-team-checkbox {
            &::after {
                border-radius: 0.25rem;
                border-color: #5e72e4;
                background-color: #5e72e4;
                background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%274%27 viewBox=%270 0 4 4%27%3e%3cpath stroke=%27%23fff%27 d=%27M0 2h4%27/%3e%3c/svg%3e");
            }
        }
    }

    .dropdown-menu {
        &.show {
            top: 40px !important;
            left: -160px !important;
        }
    }
}