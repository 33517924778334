.clients-contract-card-container {
    border-radius: 5px;
    width: 100%;
    overflow-y: auto;

    .client-contract-card {
        border-bottom: 1px solid rgb(234, 234, 234);
    }

    .client-profile-card {
        height: 100%;
        overflow-y: auto;
    }
}

.client-invoice-card-container {
    min-height: 500px;
}
