.org-level-map{
    .reset-btn{
        position: relative;
        bottom: 145px;
        padding: 8px;
        font-weight: bolder;
        font-size: 1.77rem;
        color: #525f7f;
        border-radius: 5px 5px 0px 0px;
        right: 9px;
    }
}