.clockin-modal-form{
    .clock-btn{
        background-color: #010a63;
        color: white;
        border:0px;
        
        &:hover{
            background-color: #32325D;
            color: white;
        }
    }

    .switch-btn{
        background-color: #010a63;
        color: white;
        border:0px;
        
        &:hover{
            background-color: #32325D;
            color: white;
        }
    }
    
    .break-btn{
        background-color:#FE9700 ;
        color: white;
        border:0px;
    
        &:hover{
            background-color: #32325D;
            color: white;
        }
    }
}