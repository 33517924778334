// /**
//  * Main application sass file that contains all the application styling
//  */ 
#main {
    display: flex;
    flex-direction: row;
    flex: 1;
}

#main .b-configpanel {
    flex: none;
}

.b-sch-event:not(.b-sch-event-milestone) {
    border-radius: 2px;
}

.selected-event {
    text-transform: uppercase;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.selected-event span {
    font-weight: 800;
}

.small-text {
    font-size: 0.7em;
}

.yes-button,
.no-button {
    flex: 1;
    border: none;
    outline: none;
}

// fixes styling bug
.b-theme-stockholm .b-has-label label {
    color: #4f5964;
}

.b-theme-classic .b-has-label label {
    color: #e6e6e6;
}

.b-theme-classic-light .b-has-label label {
    color: #616161;
}

.b-theme-classic-dark .b-has-label label {
    color: #c0c1c2;
}

.b-theme-classic-material .b-has-label label {
    color: #616161;
}

.b-theme-classic-dark {
    .b-nested-events-container {
        background-image: repeating-linear-gradient(135deg, #777, #777 3px, #444 3px, #444 6px);
    }

    .b-sch-event-selected,
    &:hover {
        .b-nested-events-container {
            // Needs !important to not have to have a very specific selector here, to keep demo css simple
            background-image: repeating-linear-gradient(135deg, #888, #888 3px, #555 3px, #555 6px) !important;
        }
    }
}

.b-sch-event-tooltip h4 {
    margin-block: 0.7em 0.2em;
    width: fit-content !important;
}

.b-icon-clock-live {
    background-color: #fff;
    border-radius: 50%;
    width: 1.7em;
    height: 1.7em;
    position: relative;
    animation-delay: 0s;
    border: 2px solid #2196f3;
}

.b-icon-clock-live:before,
.b-icon-clock-live:after {
    position: absolute;
    display: block;
    font-size: 1.7em !important;
    content: "";
    width: 0.1em !important;
    left: 0.37em;
    /* half width of clock - half width of hand */
    background-color: rgba(119, 119, 119, 0.5);
    border-radius: 0.5em;
}

.b-icon-clock-live:before {
    top: 0.01em;
    height: 0.5em !important;
    /* half the width/height of the clock */
    transform-origin: 0.034em 0.47em;
    animation: rotate 6s infinite linear;
    animation-play-state: paused;
    animation-delay: inherit;
}

.b-icon-clock-live:after {
    top: 0.22em;
    height: 0.33em;
    /* top + height for little hand must = top + height for big hand */
    transform-origin: 0.034em 0.47em;
    animation: rotate 0s infinite linear;
    /* time should be big-hand time * 12 */
    animation-play-state: paused;
    animation-delay: inherit;
}

.b-sch-resourcenonworkingtime {
    background: rgba(0, 0, 0, 0.078) !important;
    color: #78838f !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    // border: 1px dashed #8898aa !important;
    // border-radius: 5px !important;
    // text-overflow: ellipsis !important;
    // font-size: 8px !important;
}

.b-stripe .b-grid-row.b-odd {
    background-color: #00000006 !important;

}

/* Animations */
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.b-tooltip-content {
    padding: 0em;
    // color: #fff;
    font-weight: 400;
    font-size: 12px;
    width: fit-content !important;
}

.b-sch-event-content {
    position: relative;
    width: 100%;
    margin: 0 !important;
    left: 0 !important;
}

.b-sch-event-wrap .b-sch-event-content div {
    flex: 1;
}

.b-resource-avatar.b-icon {
    font-size: 1.2em;
}

.b-sch-event .b-sch-event-content,
.b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event,
.b-sch-event.b-milestone label {
    font-size: 12px !important;
}

.b-timeline-subgrid div.b-sch-line {
    border-inline-start: 2px solid rgba(250, 176, 5, 0.4) !important;
    color: #fff;
    padding: 0;
    z-index: 8;
}

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event:not(.b-milestone) {
    color: #d4ad02;
}

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event:not(.b-milestone).b-sch-event-selected,
.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event:not(.b-milestone).b-sch-event-selected * {
    color: #8f7505;
}

.b-sch-event {
    padding-inline-start: 0;
}


.b-timeline-subgrid div.b-sch-line label {
    background-color: rgba(250, 176, 5, 0.4) !important;
    padding: 0.3em;
    white-space: nowrap;
}

.b-grid-header .b-sch-timerange.b-sch-line {
    background-color: rgba(250, 176, 5, 0.4) !important;
}

.b-timeline-subgrid div.b-sch-current-time {
    border-inline-start: 2px solid rgba(250, 82, 82, 0.4) !important;
}

.b-timeline-subgrid .b-sch-current-time label {
    background-color: rgba(250, 82, 82, 0.7) !important;
}

.b-grid-header .b-sch-timerange.b-sch-current-time {
    background-color: rgba(250, 82, 82, 0.75) !important;
    font-weight: 600;
}

.b-stickyevents:where(.b-sch-horizontal) .b-sch-event-wrap:where(:not(.b-disable-sticky, .b-milestone-wrap)) .b-sch-event-content,
.b-stickyevents:where(.b-sch-horizontal) .b-sch-resourcetimerange .b-sch-event-content {
    height: 100%;
}

.event-color-red-lines {
    color: #b10f0f;
    font-weight: 500;
    border-color: #b10f0f;
    background-image: linear-gradient(-45deg,
            rgba(0, 0, 0, 0) 46%,
            #e03218 30%,
            #e03218 30%,
            rgba(0, 0, 0, 0) 52%);
    background-size: 6px 6px !important;
    background-color: transparent;

    &:hover,
    &.selected {
        background-color: #e88f8f;
        background-image: none;
        color: white
    }

    &.selected:hover {
        background-color: #e88f8f;
    }

}

.holiday-tooltip {
    .bs-tooltip-right .arrow::before,
    .bs-tooltip-auto[x-placement^="right"] .arrow::before {
        border-right-color: white;
        background: transparent;
    }

    .tooltip {
        &.show {
            opacity: 1;
        }

        .tooltip-inner {
            min-width: 150px;
            max-width: fit-content;
            background-color: white;
            padding: 1rem 2rem;
            box-shadow: 0px 15px 35px #32325D33;

            .content {
                color: #000;
                display: grid;
                grid-template-columns: auto auto;
                padding: 0;
                margin: 0;

                li {
                    margin: 5px 15px;
                }
            }
        }
    }
}

.b-sch-header-timeaxis-cell,
.b-sch-header-row {
    contain: none;
}

.b-sch-event-wrap {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.18);
}

.b-sch-event-wrap .b-sch-event {
    background: #fff !important;
    border-inline-start: 0px;
}

.b-sch-event-wrap .b-sch-event-selected {
    background-color: #ffeacb !important;
    font-weight: 500 !important;
}

.b-sch-event-wrap .b-sch-event-content div {
    flex: 1;
}

.b-sch-event-wrap .b-sch-event-content * {
    overflow: hidden;
    text-overflow: ellipsis;
}

.b-border-default {
    border-inline-start-color: #5428E0 !important;
    border-inline-start-style: solid;
    background-color: #5328e011;
}

.b-border-submitted {
    border-inline-start-color: #AD3E6D !important;
    border-inline-start-style: solid;
    background-color: #ad3e6c15;
}

.b-border-approved {
    border-inline-start-color: green !important;
    border-inline-start-style: solid;
    background-color: rgba(0, 128, 0, 0.045);
}

.d-border-width {
    border-inline-start-width: 10px !important;
}

.w-border-width {
    border-inline-start-width: 7px !important;
}

.m-border-width {
    border-inline-start-width: 4px !important;
}


.b-content {
    font-size: 11px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;

    span {
        text-align: center;
    }
}

.scheduler-drawer {
    min-width: 300px;
    max-width: 800px;

    .content {
        .card-body {
            margin: 0;
            padding: 0;
            // max-height: calc(100% - 50px) !important;
        }
    }
}

.scheduler-tooltip-table {
    width: 100% !important;
    thead {
        tr {
            height: 20px;

            th {
                padding: 10px 0px !important;
                text-align: center;
                font-size: 12px !important;
                text-transform: none !important;
            }
        }
    }

    tbody {
        width: 100% !important;
        tr {
            height: 20px;

            td {
                padding: 10px !important;
                text-align: center;
                font-size: 12px;
            }
        }
    }
}

.shift-trade-drawer {
    max-width: 800px;
}


.b-external-grid .b-grid-empty .b-empty-text {
    width: calc(100% - 2em);
    height: calc(100% - 2em);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3em;
    color: #aaa !important;
    border: 2px dashed #d4d4d4;
    border-radius: 7px;
    margin: 1em;
}

.b-external-grid.b-drop-target .b-empty-text {
    border-color: #777;
    color: #777 !important;
}

.unscheduled-shifts-content {
    height: 50px;
    // width: 100% !important;
    background-color: #FFFBD6  !important;
    border-left: 5px solid #FEEE61;
}

.b-drag-proxy.week {
    height: 56px !important;
    font-size: 10px !important;
    width: 80px !important;
    text-wrap: wrap;
    border: 0;
}

.b-drag-proxy.month {
    height: 56px !important;
    font-size: 9px !important;
    width: 30px !important;
    text-wrap: wrap;
}

.b-drag-proxy.custom {
    height: 56px !important;
    font-size: 9px !important;
    width: 30px !important;
    text-wrap: wrap;
}

.b-drag-proxy.pay-group {
    width: 30px !important;
    text-wrap: wrap;
}

.b-drag-proxy.date {
    width: 100%;
    text-wrap: wrap;
}

.b-external-grid .b-grid-row {
    height: 58px !important;
    &.b-selected {
        background-color: transparent !important;
        // .b-grid-cell.unscheduled-shifts {
        //     // background-color: #FFEACB !important;
        //     &:hover {
        //         background-color: #FFEF62 !important;
        //         border: 1px solid #FFEF62;
        //         color: #B6A13F;
        //     }
        // }
    }
}

.b-grid-cell.unscheduled-shifts {
    background-color: #FFFBD6  !important;
    color: #E4CA57 !important;
    height: 54px;
    margin: 2px 2px 0px 0px;
    border-left: 5px solid #FEEE61;

    .week {
        justify-content: start;
        display: flex;
        flex-direction: column;
        .shift-detail {
            display: flex;
            justify-content: start;
            flex-direction: column;
            span {
                text-align: left;
            }
        }
    }
    &:hover {
        background-color: #FFEF62 !important;
        border: 1px solid #FFEF62;
        color: #B6A13F;
    }
}