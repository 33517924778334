// /**
//  * Main application sass file that contains all the application styling
//  */ 
#main {
    display: flex;
    flex-direction: row;
    flex: 1;
}
#main .b-configpanel {
    flex: none;
}

.b-sch-event:not(.b-sch-event-milestone) {
    border-radius: 2px;
}

.selected-event {
    text-transform: uppercase;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.selected-event span {
    font-weight: 800;
}

.small-text {
    font-size: 0.7em;
}

.yes-button,
.no-button {
    flex: 1;
    border: none;
    outline: none;
}

// fixes styling bug
.b-theme-stockholm .b-has-label label {
    color: #4f5964;
}

.b-theme-classic .b-has-label label {
    color: #e6e6e6;
}

.b-theme-classic-light .b-has-label label {
    color: #616161;
}

.b-theme-classic-dark .b-has-label label {
    color: #c0c1c2;
}

.b-theme-classic-material .b-has-label label {
    color: #616161;
}

// .b-sch-event-wrap {
//     .b-sch-event {
//         background-color: #fff7d7;
//         color: #d4ad02;
//         border-left: 3px solid #fdd217;

//         &-resizable-true {
//             &::before, &::after {
//                 background-color: #fdd217;
//             }
//         }

//         &-selected {
//             background-color: #d4ad02;
//             color: white;
//             border-left-color: transparent;

//             &, * {
//                 font-weight: 400;
//             }
//         }
//     }

//     .b-nested-events-container {
//         background-image : repeating-linear-gradient(135deg, #eee, #eee 3px, #fff 3px, #fff 6px);
//     }

//     .b-sch-event-selected,
//         &:hover {
//         .b-nested-events-container {
//             // Needs !important to not have to have a very specific selector here, to keep demo css simple
//             background-image : repeating-linear-gradient(135deg, #e8e8e8, #e8e8e8 3px, #fff 3px, #fff 6px) !important;
//         }
//     }
// }

.b-theme-classic-dark {
    .b-nested-events-container {
        background-image : repeating-linear-gradient(135deg, #777, #777 3px, #444 3px, #444 6px);
    }

    .b-sch-event-selected,
    &:hover {
        .b-nested-events-container {
            // Needs !important to not have to have a very specific selector here, to keep demo css simple
            background-image : repeating-linear-gradient(135deg, #888, #888 3px, #555 3px, #555 6px) !important;
        }
    }
}

.b-sch-event-tooltip h4 {
    margin-block : 0.7em 0.2em;
}

.b-icon-clock-live {
    background-color: #fff;
    border-radius: 50%;
    width: 1.7em;
    height: 1.7em;
    position: relative;
    animation-delay: 0s;
    border:2px solid #2196f3;
}

.b-icon-clock-live:before, .b-icon-clock-live:after {
    position: absolute;
    display: block;
    font-size: 1.7em !important;
    content: "";
    width: 0.1em !important;
    left: 0.37em; /* half width of clock - half width of hand */
    background-color: rgba(119, 119, 119, 0.5);
    border-radius: 0.5em;
}
.b-icon-clock-live:before {
    top: 0.01em;
    height: 0.5em !important; /* half the width/height of the clock */
    transform-origin: 0.034em 0.47em;
    animation: rotate 6s infinite linear;
    animation-play-state: paused;
    animation-delay: inherit;
}

.b-icon-clock-live:after {
    top: 0.22em;
    height: 0.33em; /* top + height for little hand must = top + height for big hand */
    transform-origin: 0.034em 0.47em;
    animation: rotate 0s infinite linear; /* time should be big-hand time * 12 */
    animation-play-state: paused;
    animation-delay: inherit;
}
  
  /* Animations */
@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.b-tooltip-content {
    padding: 0em;
    // color: #fff;
    font-weight: 400;
    font-size: 12px;
}
  
.b-sch-event-content {
    position: relative;
    width: 100%;
    margin: 0!important;
    left: 0!important;
}

.b-sch-event-wrap .b-sch-event-content div {
    flex: 1;
}

.b-resource-avatar.b-icon {
    font-size: 1.2em;
}

.b-sch-event .b-sch-event-content,
.b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event,
.b-sch-event.b-milestone label {
    font-size: 12px!important;
}

.b-timeline-subgrid div.b-sch-line{
    border-inline-start: 2px solid rgba(250, 176, 5, 0.4) !important;
    color:#fff;
    padding:0;
    z-index:8;
}

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event:not(.b-milestone) {
    color: #d4ad02;
}
.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event:not(.b-milestone).b-sch-event-selected,
.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event:not(.b-milestone).b-sch-event-selected * {
    color: #8f7505;
}

.b-sch-event {
    padding-inline-start: 0;
}


.b-timeline-subgrid div.b-sch-line label{
    background-color: rgba(250, 176, 5, 0.4) !important;
    padding:0.3em;
    white-space:nowrap;
}

.b-grid-header .b-sch-timerange.b-sch-line{
    background-color: rgba(250, 176, 5, 0.4) !important;
}

.b-timeline-subgrid div.b-sch-current-time{
    border-inline-start: 2px solid rgba(250, 82, 82, 0.4) !important;
}

.b-timeline-subgrid .b-sch-current-time label{
    background-color: rgba(250, 82, 82, 0.7) !important;
}

.b-grid-header .b-sch-timerange.b-sch-current-time{
    background-color: rgba(250, 82, 82, 0.75) !important;
    font-weight: 600;
}

.eventWithAgenda {
    &.b-sch-event-selected {
        .b-sch-event-content {
            .nested {
                &.red-lines {
                    background-color: #e88f8f !important;
                    background-image: none !important;
                    color: white !important;

                    * {
                        color: white !important;
                    }
                }
                &.overtime {
                    background-color: #b10f0f !important;
                    color: white !important;

                    * {
                        color: #fff !important;
                    }
                }
            }
        }

    }
    .b-sch-event-content {
        background-color: inherit;

        .nested {
            position: absolute;
            background-color: inherit;
            font-size: .8em;
            display: flex;
            justify-content: center;
            align-items: center;
            &.week {
                justify-content: start;
                .shift-detail {
                    display: flex;
                    justify-content: start;
                    flex-direction: column;
                    span {
                        text-align: left;
                    }
                }
            }
            .shift-detail {
                display: flex;
                justify-content: center;
                flex-direction: column;
                span {
                    text-align: center;
                }
            }
            &.break {
                background-image: repeating-linear-gradient(45deg,
                 #ddd, #ddd 10px,
                 #eee 0, #eee 20px);
                text-align: center;
                font-size: 11px;
                color: #ef4a05 !important;
            }
            &.uat {
                background-image: repeating-linear-gradient(45deg,
                    #ddd,
                    #ddd 10px,
                    #eee 10px,
                    #eee 20px
                );
                text-align: center;
                font-size: 11px;
                color: #ef4a05 !important;
            }

            &.red-lines {
                color: #b10f0f!important;
                font-weight: 500;
                border-color: #b10f0f!important;
                background-image: linear-gradient(-45deg,
                    rgba(0, 0, 0, 0) 46%,
                    #e03218 30%,
                    #e03218 30%,
                    rgba(0, 0, 0, 0) 52%);
                background-size: 6px 6px !important;
                background-color: white;
                * {
                    color: #b10f0f!important;
                }
            }

            &.overtime {
                color: #fff !important;
                font-weight: 500;
                border-color: #e88f8f !important;
                background-color: #e88f8f;

                * {
                    color: #b10f0f !important;
                }
            }
        }
    }
}
.b-sch-horizontal .nested {
    top: 0;
    bottom: 0;
}


.b-sch-vertical .nested {
    left: 0;
    right: 0;
    padding-top: .5em;
}

.b-stickyevents:where(.b-sch-horizontal) .b-sch-event-wrap:where(:not(.b-disable-sticky, .b-milestone-wrap)) .b-sch-event-content,
.b-stickyevents:where(.b-sch-horizontal) .b-sch-resourcetimerange .b-sch-event-content {
    height: 100%;
}

.event-color-red-lines {
    color: #b10f0f;
    font-weight: 500;
    border-color: #b10f0f;
    background-image: linear-gradient(-45deg,
            rgba(0, 0, 0, 0) 46%,
            #e03218 30%,
            #e03218 30%,
            rgba(0, 0, 0, 0) 52%);
    background-size: 6px 6px !important;
    background-color: transparent;

    &:hover, 
    &.selected {
        background-color: #e88f8f;
        background-image: none;
        color: white
    }
    &.selected:hover {
        background-color: #e88f8f;
    }

}

// .b-sch-header-row:nth-child(3) .b-sch-header-timeaxis-cell {
//     background-color: white;
//     font-weight: 600;
//     font-size: 10px;
// }

.holiday-tooltip {
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before{
        border-right-color: white;
        background: transparent;
    }

    .tooltip{
        &.show{
            opacity:1;
        }

        .tooltip-inner {
            min-width: 150px;
            max-width: fit-content;
            background-color: white;
            padding: 1rem 2rem;
            box-shadow: 0px 15px 35px #32325D33;  
            .content {
                color: #000;
                display: grid;
                grid-template-columns: auto auto;
                padding: 0;
                margin: 0;
                li {
                    margin: 5px 15px;
                }
            }
        }
    }
}

.b-sch-header-timeaxis-cell, .b-sch-header-row {
    contain: none;
}