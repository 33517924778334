.timeclock-team {
    .item {
        display: flex;
        align-items: center;

        .icon {
           padding: 3px;
           font-size: 0.7rem;
           border-radius: 50%;
           font-weight: bold;
           text-align: center;
           margin-right: 7px;
           height: 15px;
           width: 15px;
        }
    }

   .form-control {
     border: 0px;
     box-shadow: none;
     height: 55px;
   }

   $status: ("on-break":#F99B16, "clocked-in": #5428e0, "clocked-out":red );

   @each $name, $value in $status {
        .text-#{$name}{
            color: $value;
            font-weight: bolder;
        }
   }

   
}

.team-list-scroll {
    overflow-x: hidden;
}

.team-list-scroll::-webkit-scrollbar-thumb {
	background-color: #5026d9;
}
.team-list-scroll::-webkit-scrollbar {
	width: 5px;         
    height: 5px;
	border: 1px solid #5026d9;
}
.team-list-scroll::-webkit-scrollbar-track {
    background-color:hsla(0, 3%, 44%, 0.75);
}

.card-height {
    height: calc(100% - 30px);
}

@media(max-width: 630px) {
    .card-height {
        height: 100%;
    }
}

@media(max-width: 800px) {
    .my-team {
        flex-direction: column;
    }
    .my-team-card {
        height: 50%;
    }
}