.allocated-resources-tooltip {
    .bs-tooltip-right .arrow::before,
    .bs-tooltip-auto[x-placement^="right"] .arrow::before {
        border-right-color: white;
        background: transparent;
    }

    .tooltip {
        &.show {
            opacity: 1;
        }

        .tooltip-inner {
            max-width: 350px;
            background-color: white;
            padding: 10px 15px;
            box-shadow: 0px 15px 35px #32325d33;
        }
    }

    .allocated-resources-container {
        color: black;
    }
}