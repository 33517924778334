.team-table {
    .custom-control.custom-checkbox {
        width: 16px;
        min-height: auto;
    }

    .table {
        th, td {
            padding: 0.5rem 0.5rem !important;
        }

        .timesheet-table-container {
            padding: 0.15rem!important;
            background-color: #f6f6f6!important;
        }

        tr {
            &.unapproved {
                background-color: rgb(240, 249, 255);
            }

            &.misspunch {
                background-color: rgb(255, 228, 230);
            }

            &.expanded {
                background-color: rgb(255, 247, 237);

                &.misspunch {
                    background-color: rgb(254, 205, 211);
                }
            }
            td {
                span {
                    &.terminated{
                        color: rgb(239, 124, 137);

                        .text-muted {
                            color: rgb(239, 124, 137) !important;
                        }
                    }

                    &.hiring {
                        color: rgb(11, 63, 28);
                        .text-muted {
                            color: rgb(11, 63, 28) !important;
                        }
                    }
                }
            }
        }
    }
}
