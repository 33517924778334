.manager-view-table {
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    width: 100%;

    thead.manager-view-table-header {
        background: #E9ECEF;
        color: #8898AA;
        font-size: 12px;
        width: 110px;

        th {
            padding: 5px;
            width: 110px !important;
            position: sticky;
            top: 0;
            z-index: 2;
            background: #E9ECEF;
            color: #8898AA;

            &.name {
                background: #E9ECEF;
                position: sticky;
                left: 0;
                z-index: 11;
                width: 170px !important;

            }

            // &.employee-number{
            //     background: #E9ECEF;
            //     position:sticky;
            //     left:200px;
            //     z-index:12;
            //     width: 200px;
            // }
            &.summary {
                background: #E9ECEF;
                position: sticky;
                left: 170px;
                z-index: 13;
                width: 170px;
            }
        }
    }
}

tbody {
    .manager-view-tr {
        td {
            padding: 5px;
            width: 170px;
            height: 70px;
            position: sticky;
            background-color: #fff;
            border-right: 1px solid rgba(0, 0, 0, 0.05);
            border-top: 1px solid rgba(0, 0, 0, 0.05);
            font-size: 12px;

            &:first-child {
                position: sticky;
                left: 0;
                z-index: 2;
            }

            &:nth-child(2) {
                position: sticky;
                left: 170px;
                z-index: 3;
                border-right: 1px solid rgba(0, 0, 0, 0.05);
            }

            // &:nth-child(3){
            //     position:sticky;
            //     left:400px;
            //     z-index:4;
            //     border-right:1px solid rgba(0, 0, 0, 0.05);
            // }
        }
    }
}

.manager-view-tfoot-tr {
    background: #f1f2f3;
    color: #8898AA;

    td {
        padding: 5px;
        width: 170px;
        height: 70px;
        position: sticky;
        background-color: #E9ECEF;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        font-size: 12px;


        &:first-child {
            position: sticky;
            left: 0;
            z-index: 2;
        }

        &:nth-child(2) {
            position: sticky;
            left: 170px;
            z-index: 3;
            border-right: 1px solid rgba(0, 0, 0, 0.05);
        }

        // &:nth-child(3){
        //     position:sticky;
        //     left:400px;
        //     z-index:4;
        //     border-right:1px solid rgba(0, 0, 0, 0.05);
        // }
    }

    .manager-view-tfoot-tr{
        background: #f1f2f3;
		color: #8898AA; 

        td {
            padding: 5px;
            width: 170px;
            height:70px;
            position:sticky;
            background-color: #E9ECEF;
            border-right:1px solid rgba(0, 0, 0, 0.05);
            border-top:1px solid rgba(0, 0, 0, 0.05);
            font-size: 12px;
            

            &:first-child {
                position:sticky;
                left:0;
                z-index:2; 
            }

            &:nth-child(2){
                position:sticky;
                left:170px;
                z-index:3;
                border-right:1px solid rgba(0, 0, 0, 0.05);
            }
            // &:nth-child(3){
            //     position:sticky;
            //     left:400px;
            //     z-index:4;
            //     border-right:1px solid rgba(0, 0, 0, 0.05);
            // }
        }

    }
}

@media(max-width: 490px) {
    .manager-view-table {
        thead.manager-view-table-header {
    
            th {
                width: 100px !important;       
            }
        }
    }
}
