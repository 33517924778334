.table-filters {

    .form-control,
    .currency-input,
    .time-input,
    .rc-select .rc-select-selector {
        height: auto;
        min-height: auto;
    }

    .form-control,
    .currency-input,
    .time-input {
        padding: 2.5px !important;
    }

    .rc-select {
        .rc-select-selector {
            padding: 1.5px !important;
        }

        .rc-select-arrow {
            right: 10px;
        }

        &.rc-select-multiple {
            .rc-select-selection-overflow {
                gap: 0.1rem 0;

                .rc-select-selection-overflow-item {
                    .rc-select-selection-item {
                        padding: 0 0.4em;
                        font-size: 90%;
                        text-align: center;
                        white-space: nowrap;
                        vertical-align: baseline;
                        border-radius: 0.25rem;
                        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                        color: #8898aa;
                        background-color: transparent;
                        border: 1px solid #dee2e6;
                        display: inline-block;

                        .rc-select-selection-item-content {
                            margin-right: 0.25rem;
                        }

                        .rc-select-selection-item-remove {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .filter-btn {
        margin-right: 15px;
    }
}

@media (max-width: 700px) {
    .responsive-filters {
        display: flex;
        flex-direction: column;
    }

    .filter-btn {
        margin-right: 5px;
    }

    .filter-buttons {
        margin-top: 5px;
    }
}