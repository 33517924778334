.communication-widget-container {
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 0.5rem;
    overflow: hidden;

    .sidebar {
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        background-color: #fff;
        color: #333;
        transition: width 0.3s ease;
        height: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        border-radius: 0.5rem 0 0 0.5rem;

        .search-bar {
            display: flex;
            align-items: center;
            background-color: #f7f7f7;
            border: 1px solid #ddd;
            margin: 1rem 0 1rem 0;
            border-radius: 0.65rem;

            input {
                background: none;
                border: 0;
                padding: 0.8rem;
                width: 100%;
                flex-grow: 1;
                box-shadow: none;
            }

            .search-icon {
                padding: 0.7rem;
                border-radius: 0.5rem;
                color: #666;
            }
        }

        .chat-list-container {
            overflow-y: auto;

            .section {
                margin-top: 1rem;

                .section-title {
                    display: flex;
                    align-items: center;
                    font-size: 1.1rem;
                    color: #555;
                    cursor: pointer;
                    padding: 0.2rem 0.5rem;

                    .section-name {
                        padding: 0.2rem 0.5rem;
                        border-radius: 0.5rem;
                        display: flex;
                        align-items: center;

                        &:hover {
                            background-color: #f0f0f0;
                        }
                    }

                    .section-icon {
                        transition: transform 0.3s ease;
                        padding: 0.5rem;
                        border-radius: 0.5rem;
                        width: 1rem;
                        height: 1rem;

                        &:hover {
                            background-color: #f0f0f0;
                        }
                    }

                    &.collapsed .section-icon {
                        transform: rotate(-90deg);
                    }
                }

                .chat-list {
                    transition: max-height 0.3s ease;

                    &.collapsed {
                        max-height: 0;
                        overflow: hidden;
                    }

                    .load-more {
                        cursor: pointer;
                        text-decoration: underline;
                        color: #666;
                        width: fit-content;
                    }

                    .chat-item {
                        margin-bottom: 0.5rem;
                        padding: 0.5rem;
                        border-radius: 0.5rem;
                        width: 100%;
                        position: relative;

                        &.selected {
                            background-color: #f0f0f0;
                        }

                        &:hover {
                            background-color: #f9f9f9;
                            cursor: pointer;
                        }

                        .chat-image {
                            flex-shrink: 0;
                            width: 2rem;
                            height: 2rem;
                            border-radius: 15%;
                            margin-right: 0.5rem;
                            object-fit: cover;
                            object-position: center;
                        }

                        .chat-status {
                            width: 0.75rem;
                            height: 0.75rem;
                            background-color: gray;
                            border-radius: 50%;
                            position: absolute;
                            top: 0.25rem;
                            left: 2.25rem;

                            &.online {
                                background-color: green;
                            }
                        }

                        .chat-details {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 0.5rem;
                            cursor: pointer;
                            transition: background-color 0.2s ease-in-out;

                            &:hover {
                                background-color: #f9f9f9;
                            }

                            .left-section {
                                display: flex;
                                align-items: center;

                                .avatar {
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    margin-right: 0.75rem;
                                }

                                .message-details {
                                    display: flex;
                                    flex-direction: column;

                                    .user-name {
                                        font-weight: 600;
                                        color: #333;
                                        font-size: 1rem;
                                    }

                                    .last-message {
                                        font-size: 0.85rem;
                                        color: #666;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        max-width: 200px;
                                    }
                                }
                            }

                            .right-section {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                                gap: 0.25rem;

                                .chat-time {
                                    font-size: 0.75rem;
                                    color: #999;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // Scrollbar styles
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #aaa;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #888;
    }

    .main-content {
        flex: 1;
        transition: margin-left 0.3s ease;
        display: flex;
        flex-direction: column;

        .details {
            display: flex;
            flex-direction: column;
            background-color: #fff;
            transition: width 0.3s ease;
            padding: 0.5rem;
            border-top: 1px solid #ddd;
    
            &.closed {
                width: 0;
                padding: 0;
                overflow: hidden;
                border-radius: 0 0 0.5rem 0.5rem;
            }
    
            .details-header {
                padding: 1rem;
                border-bottom: 1px solid #ddd;
                font-weight: bold;
            }
    
            .details-content {
                flex: 1;
                padding: 1rem;
                overflow-y: auto;
            }
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #e1e4e8;
            background-color: #fff;
            padding: 0.5rem 1rem;

            .toggle-sidebar,
            .toggle-chat-details {
                align-self: flex-end;
                background: none;
                border: none;
                color: #333;
                cursor: pointer;
                font-size: 1.5rem;

                &:hover {
                    color: #007bff;
                }
            }

            .header-name {
                display: flex;
                align-items: center;
                font-weight: 600;
                color: #333;
                margin: 0;
            }
        }

        .conversation-content {
            flex: 1;
            overflow: auto;
            display: flex;
            flex-direction: column-reverse;
            position: relative;
            background-color: #fff;

            .message-item {
                position: relative;
                display: flex;
                flex-direction: column;
                padding: 0.5rem;
                margin-bottom: 0.5rem;
                transition: background-color 0.2s;

                &:hover {
                    background-color: #e1e4e8;
                    border-radius: 0.25rem;
                }

                &:hover .message-actions {
                    display: flex;
                }

                .message-header {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    margin-bottom: 1.5rem;

                    .avatar {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        margin-right: 0.75rem;
                    }

                    .message-info {
                        display: flex;
                        flex-direction: column;
                    }

                    .sender-name {
                        font-weight: bold;
                        color: #333;
                    }

                    .message-time {
                        font-size: 0.75rem;
                        color: #888;
                        margin-left: 0.5rem;
                    }
                }

                .message-content {
                    color: #333;
                    font-size: 0.9rem;
                    display: flex;

                    .edited-tag {
                        font-size: 0.8rem;
                        color: #888;
                        margin-left: 0.5rem;
                    }
                }

                .message-attachments {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 10px;
                    margin-left: 3rem;

                    img.attachment-preview {
                        max-width: 150px;
                        max-height: 150px;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        border-radius: 5px;
                        object-fit: cover;
                    }
                }

                .message-actions {
                    display: none;
                    margin-left: 3rem;

                    button {
                        background: none;
                        border: none;
                        cursor: pointer;
                        font-size: 0.8rem;
                        box-shadow: none;
                        padding: 0px;

                        &:hover {
                            text-decoration: underline;
                            box-shadow: none;
                        }
                    }
                }
            }
        }

        .message-input {
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 1rem;
            background-color: #fff;
            border-top: 1px solid #e1e4e8;

            .typing-indicator {
                margin-bottom: 0.5rem;
                font-size: 0.8rem;
                color: #888;
                margin-left: 0.5rem;
                font-style: italic;
            }

            .attachments-preview {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 0.5rem;

                .attachment {
                    position: relative;
                    margin-right: 0.5rem;
                    margin-bottom: 0.5rem;

                    img {
                        width: 60px;
                        height: 60px;
                        object-fit: cover;
                        border-radius: 0.5rem;
                        border: 1px solid #ddd;
                    }

                    button {
                        position: absolute;
                        top: -5px;
                        right: -5px;
                        background: red;
                        color: white;
                        border: none;
                        border-radius: 50%;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 0.8rem;
                    }
                }
            }

            .send-message-actions {
                display: flex;
                align-items: center;
                justify-content: space-between;

                label {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    font-size: 1.5rem;
                    margin-right: 0.5rem;
                    transition: color 0.2s;

                    &:hover {
                        color: #0056b3;
                    }
                }
            }
        }
    }
}