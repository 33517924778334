.allocation-table-container {
    height: 80%;
}

.allocation-drawer {
    width: 100%;
    max-width: 1250px;
}

.total-input-height {
    height: 30px;
    width: 90px;
}

.employee-search-input {
    padding-left: 2.5rem !important;
}