.company-onboarding-layout {
    $step-height: 150px;
    height: 100%;

    .bg-gradient-launch {
        background: linear-gradient(87deg, #5428e0 0,#0f3c76 100%) !important;
    }

    .steps-card {
        height: $step-height;

        .step-wizard-list-launch {
            margin-bottom: 0px;
            grid-template-columns: repeat(6, 1fr);

            li {
                margin-bottom: 0px;
            }
        }
    }

    .body {
        height: calc(100% - #{$step-height});

        form {
            height: 100%;
            $actions-height: 75px;

            .actions {
                height: $actions-height;
            }

            > .card-body {
                height: calc(100% - #{$actions-height});
                overflow: auto;
            }
        }
    }
}
