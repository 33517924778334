.switch-component{
    .form-check-input {
        width: 1.23em;
        height: 1.23em;
        margin-top: 0.135em;
        vertical-align: top;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: none;
        appearance: none;
        print-color-adjust: exact;
        transition: background-color 0.25s ease, border-color 0.25s ease, background-position 0.15s ease-in-out, opacity 0.15s ease-out, box-shadow 0.15s ease-in-out;
      }
      
      @media (prefers-reduced-motion: reduce) {
        .form-check-input {
          transition: none;
        }
      }
      
      .form-check-input[type="checkbox"] {
        border-radius: 0.35rem;
      }
      
      .form-check-input[type="radio"] {
        border-radius: 50%;
      }
      
      .form-check-input:active {
        filter: brightness(99%);
      }
      
      .form-check-input:focus {
        border-color: none;
        outline: 0;
        box-shadow: none;
      }
      
      .form-check-input:checked {
        background-color: transparent;
        border-color: transparent;
      }
      
      .form-check-input:checked[type="checkbox"] {
        background-image: linear-gradient(310deg, #010a63 0%, #825ee4 100%);
      }
      
      .form-check-input:checked[type="radio"] {
        background-image: linear-gradient(310deg, #010a63 0%, #825ee4 100%);
      }
      
      .form-check-input[type="checkbox"]:indeterminate {
        background-color: #010a63;
        border-color: #010a63;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
      }
      
      .form-check-input:disabled {
        pointer-events: none;
        filter: none;
        opacity: 0.5;
      }
      
      .form-check-input[disabled]~.form-check-label,
      .form-check-input:disabled~.form-check-label {
        cursor: default;
        opacity: 0.5;
      }
      
      .form-switch {
        padding-left: 3rem;
      }
      
      .form-switch .form-check-input {
        width: 2.8rem;
        margin-left: -3rem;
        background-image: none;
        background-position: left center;
        border-radius: 2.5rem;
        transition: background-color 0.25s ease, border-color 0.25s ease, background-position 0.15s ease-in-out, opacity 0.15s ease-out, box-shadow 0.15s ease-in-out;
      }
      
      @media (prefers-reduced-motion: reduce) {
        .form-switch .form-check-input {
          transition: none;
        }
      }
      
      .form-switch .form-check-input:focus {
        background-image: none;
      }
      
      .form-switch .form-check-input:checked {
        background-position: right center;
        background-image: none;
      }
      
      .form-switch.form-check-reverse {
        padding-right: 3rem;
        padding-left: 0;
      }
      
      .form-switch.form-check-reverse .form-check-input {
        margin-right: -3rem;
        margin-left: 0;
      }
      
      .form-check-inline {
        display: inline-block;
        margin-right: 1rem;
      }
    
      .form-check:not(.form-switch) .form-check-input[type="checkbox"],
    .form-check:not(.form-switch) .form-check-input[type="radio"] {
      border: 1px solid #cbd3da;
      margin-top: 0.25rem;
      position: relative;
    }
    
    .form-check:not(.form-switch) .form-check-input[type="checkbox"]:checked,
    .form-check:not(.form-switch) .form-check-input[type="radio"]:checked {
      border: 0;
    }
    
    .form-check:not(.form-switch) .form-check-input[type="checkbox"]:after {
      transition: opacity 0.25s ease-in-out;
      font-family: "FontAwesome";
      content: "\f00c";
      width: 100%;
      height: 100%;
      color: #fff;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.67rem;
      opacity: 0;
    }
    
    .form-check:not(.form-switch) .form-check-input[type="checkbox"]:checked:after {
      opacity: 1;
    }
    
    .form-check:not(.form-switch) .form-check-input[type="radio"] {
      transition: border 0s;
    }
    
    .form-check:not(.form-switch) .form-check-input[type="radio"]:after {
      transition: opacity 0.25s ease-in-out;
      content: "";
      position: absolute;
      width: 0.4375rem;
      height: 0.4375rem;
      border-radius: 50%;
      background-color: #fff;
      opacity: 0;
    }
    
    .form-check:not(.form-switch) .form-check-input[type="radio"]:checked {
      padding: 6px;
    }
    
    .form-check:not(.form-switch) .form-check-input[type="radio"]:checked:after {
      opacity: 1;
    }
    
    .form-switch {
        display: flex;
        align-items: center;
      }
      
      .form-switch .form-check-input {
        border: 1px solid #e9ecef;
        position: relative;
        background-color: rgba(33, 37, 41, 0.1);
        height: 1.25em;
      }
      
      .form-switch .form-check-input:after {
        transition: transform 0.25s ease-in-out, background-color 0.25s ease-in-out;
        content: "";
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        position: absolute;
        background-color: #fff;
        transform: translateX(1px);
        box-shadow: 0 0.25rem 0.375rem -0.0625rem rgba(20, 20, 20, 0.12), 0 0.125rem 0.25rem -0.0625rem rgba(20, 20, 20, 0.07);
        top: 1px;
      }
      
      .form-switch .form-check-input:checked:after {
        transform: translateX(21px);
      }
      
      .form-switch .form-check-input:checked {
        border-color: rgba(94, 114, 228, 0.95);
        background-color: rgba(94, 114, 228, 0.95);
      }
      
      .form-switch .form-check-label {
        margin-bottom: 0;
        margin-left: .5rem;
      }
    
      .rtl .form-check.form-switch .form-check-input:after {
        transform: translateX(-1px);
      }
      
      .rtl .form-check.form-switch .form-check-input:checked:after {
        transform: translateX(-21px);
      }
      
}