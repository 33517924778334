.edit-request-drawer {
    .shifts-table td {
        padding: 7px !important;
        vertical-align: middle;

        .rc-select {
            width: 80px !important;
            text-align: start;

            .rc-select-clear {
                right: 10px;
            }
        }

        .rc-select-single:not(.rc-select-customize-input) .rc-select-selector,
        .form-control {
            height: 20px !important;
            padding: 5px !important;
            min-height: 20px;
            max-width: 80px;
        }

        input[type="number"i] {
            width: 60px;
        }

        .current-time-input {
            min-width: 80px !important;
            width: 80px !important;
            text-align: center;
        }
    }

    .pay-code-select .rc-select-selection-placeholder {
        text-align: start;
    }

    .card-body {
        padding: 0;
    }
}

.statistics-wrapper {
    overflow-x: auto;
    white-space: nowrap;
    padding-right: 1rem;
    padding-left: 1rem;

    .plans-container {
        display: flex;
        flex-wrap: nowrap;
    }

    .small-statistic {
        padding: 1rem;
        height: 100%;

        .plan-details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
        }

        .nested-cards {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 0.5rem;

            .nested-card {
                background: #f8f9fa;
                padding: 0.75rem;
                box-sizing: border-box;

                .title {
                    font-weight: 500;
                    font-size: 0.875rem;
                    margin-bottom: 0.25rem;
                }

                .value {
                    font-weight: 700;
                    font-size: 1rem;
                }
            }
        }

        h5 {
            font-weight: 600;
            font-size: 1rem;
        }

        .plan-description {
            font-size: 0.875rem;
            color: #6c757d;
        }

        &.light {
            background: #e9ecef;

            .title,
            .value {
                color: #495057;
            }
        }

        &.dark {
            background: #343a40;

            .title,
            .value {
                color: white;
            }
        }
    }
}

.request-reason-tooltip {
    .bs-tooltip-left .arrow::before, 
    .bs-tooltip-left[x-placement^="left"] .arrow::before {
        border-left-color: white;
        background: transparent;
    }
    
    .tooltip{
        &.show{
            opacity:1;
        }

        .tooltip-inner {
            min-width: 350px;
            max-width: none;
            max-height: 470px;
            overflow: auto;
            background-color: #fff;
            padding: 1.2rem;
            box-shadow: 0px 15px 35px #32325D33;   
            color: #000;

            .item {
                border: 1px dashed #dee2e6;
            }

            table {
                tr {
                    padding: 5px;
                }
            }
        }
    }
}