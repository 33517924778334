.modules{
    .btn-outline-secondary:hover:not(.active){
        background-color: #e4f2f3;
    }

    .btn-outline-secondary::after:not(.active){
        background-color: red;
    }

    .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle{
        background-color: #c4faf1;
        
    }

    .module{
        img{
            color: #fff;
            display: inline-flex;
            align-items: center;
            margin-bottom: 5px;
            justify-content: center;
            font-size: 1rem;
            border-radius: 0.75rem;
            height: 45px;
            width: 45px;
            transition: all .2s ease-in-out;
            // box-shadow: 0 0.25rem 0.375rem -0.0625rem rgba(20, 20, 20, 0.12), 0 0.125rem 0.25rem -0.0625rem rgba(20, 20, 20, 0.07) !important;
            }
    }
}
