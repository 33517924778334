.pay-policy-steps{

    .bg-gradient-launch {
        background: linear-gradient(87deg, #0f3c76 0, #5428e0 100%) !important;
    }
    
    .scroll-x{
        overflow-x: hidden;
    }

    .step-wizard {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        border-bottom: 0;
        
        .nav-item {
            flex: 1;
            
            .nav-link {
                border: 1px solid #79dce4;
                border-right: none;
                border-radius: 0;
                text-align: center;
                background-color: #f8f9fa;
                color: #495057;
                padding: 10px 20px;
                transition: background-color 0.3s, color 0.3s;

                &:hover {
                    background-color: #e2e6f9;
                    color: #495057;
                }

                &.active {
                    background-color: #79dce4;
                    color: #fff;
                    border-color: #79dce4;
                    z-index: 1;
                }
            }

            &:first-child .nav-link {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }

            &:last-child .nav-link {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                border-right: 1px solid #79dce4;
            }
        }
    }

    .dashed {
        border-style: dashed;
        border-width: 1px;
        border-color: #dddddd;
        border-radius: 4px;
        margin-bottom: 32px;
    }
}
