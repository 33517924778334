.shift-summary-modal {
    height: 600px;

    .approve-btn{
        background-color: #fff;
        color: #010a63;
        border:0px;
        
        &:hover{
            background-color: #32325D;
            color: #fff;
        }
    }

    .something-went-wrong-btn{
        background-color: #fff;
        color: red;
        border:0px;
        
        &:hover{
            background-color: red;
            color: #fff;
        }
    }
}
