.timesheet-analytics {
    .hour-type-amount {
        color: #bb036c;
    }

    .hour-type-unit {
        color: #fbc2e3;
    }

    .small-statistic {
        display: flex;
        justify-content: center;
        padding: 1rem;

        width: 150px;
        height: 100%;

        .title {
            margin-bottom: 0;
            font-weight: 500;
            font-size: 0.9rem;
        }

        .value {
            margin-bottom: 0;
            font-weight: 900;
            font-size: 1rem;
        }

        &.light {
            background: #e9ecef;

            .title, .value {
                color: #8898aa;
            }
        }

        &.dark {
            background: #8898aa;

            .title, .value {
                color: white;
            }
        }
    }
}

@media(max-width: 991px) {
    .timesheet-analytics {
        .small-statistic {
            width: 110px;
        }
    }
}
