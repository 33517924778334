.time-off-container-height {
    height: calc(100% - 45px);
}

.calendar-container {
    height: 100%;

    .fc-event {
        cursor: pointer;
    }

    .fc-daygrid-day-events {
        min-height: 6em !important;
    }

    .fc-daygrid-day-number {
        width: 100%;
    }

    .cell-view-more-link {
        color: blue;
        text-decoration: underline;
        margin-top: 3px;
        margin-left: 6px;
    }

    .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
        height: 100%;
    };

    .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
        height: calc(100% - 50px);
    }

    .fc-prev-button {
        margin-right: 4px;
        height: 40px;
        padding: 0px;
        box-shadow: none !important;
        border: none;
        background-color: white !important;
        color: #010a63;

        .fc-icon.fc-icon-chevron-left {
            line-height: 30px;
        }
    }

    .fc-next-button {
        height: 40px;
        padding: 0px;
        box-shadow: none !important;
        border: none;
        background-color: white !important;
        color: #010a63;

        .fc-icon.fc-icon-chevron-right {
            line-height: 30px;
        }
    }

    .fc-scrollgrid-section.fc-scrollgrid-section-header  {
        border-bottom: 1px black solid;
    }

    .fc th:last-child {
        border-right: 1px solid rgb(243, 243, 243) !important;
    }

    .fc th {
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
    }

    .fc .fc-col-header-cell.fc-day {
        text-align: center !important;
        font-size: 12px;
        font-weight: bold;
        vertical-align: unset !important;
    }

    .fc .fc-button .fc-icon {
        vertical-align: unset !important;
    }

    .fc-icon {
        width: 35px;
    }

    .fc-button-group {
        display: flex;
        gap: 5px;
    }

    .fc-dayGridMonth-button.fc-button.fc-button-primary {
        border-radius: 5px;
        background-color: #fff !important;
        border: 1px solid #010a63 !important;
        color: #010a63 !important;
        box-shadow: none !important;

        &:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
            background-color: #010a63 !important;
            color: #fff !important;
            border: none !important
        }
    }

    .fc-dayGridWeek-button.fc-button.fc-button-primary {
        border-radius: 5px;
        background-color: #fff !important;
        border: 1px solid #010a63 !important;
        color: #010a63 !important;
        box-shadow: none !important;

        &:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
            background-color: #010a63 !important;
            color: #fff !important;
            border: none !important
        }
    }

    .fc-dayGridDay-button.fc-button.fc-button-primary {
        border-radius: 5px;
        background-color: #fff !important;
        border: 1px solid #010a63 !important;
        color: #010a63 !important;
        box-shadow: none !important;

        &:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
            background-color: #010a63 !important;
            color: #fff !important;
            border: none !important
        }
    }

    .view-toggle-filter {
        z-index: 10;
        right: 87px;
    } 

    .fc-day-today.fc-daygrid-day.blackout-dates {
        background-color: #e5e7eb52 !important;
    }
}