.reports-scroll{
    overflow-x: hidden;
}

.reports-scroll::-webkit-scrollbar-thumb {
	background-color: #5026d9;
}
.reports-scroll::-webkit-scrollbar {
	width: 5px;         
    height: 5px;
	border: 1px solid #5026d9;
}
.reports-scroll::-webkit-scrollbar-track {
    background-color:hsla(0, 3%, 44%, 0.75);
}

.menu-scroll{
    overflow-y: "auto";
	overflow-x: "hidden";
	height: calc(100% - 1rem);
}

.columns-list-scroll{
    overflow-y: "auto";
	height: calc(100% - 2rem);
}