@for $i from 0 through 20 {
    .gap-#{$i} {
        gap: #{$i * 0.25}rem #{$i * 0.25}rem !important;
    }

    .gap-x-#{$i} {
        gap: 0rem #{$i * 0.25}rem !important;
    }

    .gap-y-#{$i} {
        gap: #{$i * 0.25}rem 0rem !important;
    }
}

.custom-control.custom-checkbox {
    padding: 0px;
    height: 16px;

    .custom-control-label {
        padding-left: 27px;
        display: flex;
        align-items: center;
    }

    ::before, ::after {
        top: 0;
        left: 0;
    }
}

.table-hover tbody {
    tr.missed-punch:hover {
        background-color: #e7899c71;
    }
}
tr.missed-punch {
    background-color: #fdb9c740;
}

.form-control, input {
    &:disabled {
        background-color: revert;
        color: revert;
        border-color: revert;
    }
}

.clock-in {
    color: #00998b;
    background-color: #00998b1a;
    border: 1px solid transparent;
    border-radius: .4375rem !important;
}
.clock-out {
    color: #ff6161;
    background-color: #ff61611a;
    border: 1px solid transparent;
    border-radius: .4375rem !important;
}