.clock-btn{
    background-color: #010a63;
    color: white;
    border:0px;
    
    &:hover{
        background-color: #32325D;
        color: white;
    }

   
}

.break-btn{
    background-color:#FE9700 ;
    color: white;
    border:0px;

    &:hover{
        background-color:#FE9700 ;
        color: white;
        border:0px;
    }
}

.bg-gradient-break {
    background: linear-gradient(-87deg, #FF5003 0, #FE9700 100%) !important;
}

.bg-dark-blue{
    background-color: #0f3c76;
}

.scroll{
    overflow-x: hidden;
}

.scroll::-webkit-scrollbar-thumb {
	background-color: #5026d9;
}
.scroll::-webkit-scrollbar {
	width: 5px;         
    height: 5px;
	border: 1px solid #5026d9;
}
.scroll::-webkit-scrollbar-track {
    background-color:hsla(0, 3%, 44%, 0.75);
}