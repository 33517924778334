.domain-input{
    .http-input{
        border-right: 0;
        background-color: rgb(228, 224, 224);
        color: #525f7f;
        border-radius: 5px 0px 0px 5px;
    }

    .suffix-input{
        border-left: 0;
        background-color: rgb(228, 224, 224);
        border-radius: 0px 5px 5px 0px;
        color: #525f7f;
    }
}