.employee-number-onboarding{
    .employee{
        padding: 10px 30px;
        min-width: 150px;
        font-size: 1.3rem;
        background-color: white;
        border-radius: 8px;
        border: none;
        transition: 0.4s;
        color: #344767;
        font-weight: 500;
    }

    .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle{
        background-color: #96ebdd;
        color: #344767;
    }

    .btn-outline-secondary:hover:not(.active){
        background-color: #5ed2bd;
        color: #344767;
    }
}

.business-industry-onboarding{
    .btn-outline-secondary {
        &.active, &:hover {
            background-color: #e4f2f3 !important;
        }
    }

    .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle{
        background-color: #c4faf1;
    }

    .business-industry-item{
        img{
            color: #fff;
            display: inline-flex;
            align-items: center;
            margin-bottom: 5px;
            justify-content: center;
            font-size: 1rem;
            border-radius: 0.75rem;
            height: 45px;
            width: 45px;
            transition: all .2s ease-in-out;
            // box-shadow: 0 0.25rem 0.375rem -0.0625rem rgba(20, 20, 20, 0.12), 0 0.125rem 0.25rem -0.0625rem rgba(20, 20, 20, 0.07) !important;
            }
    }
}
