.view-post-image-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9998;
    
    .view-post-image {
        position: relative;
        border-radius: 8px;
        padding: 10px 50px;
        max-width: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .close-button-container {
        position: absolute;
        background-color: black;
        padding: 0 12px;
        top: 10px;
        right: 50px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;
        z-index: 10000;  // Ensure the close button is always on top
    }
    
    .close-button {
        cursor: pointer;
        font-size: 20px;
        color: #fff;
        transition: color 0.2s;
        
        &:hover {
            color: #ff5a5a;
        }
    }
    
    .image-container {
        max-width: 100%;
        max-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.img-fluid {
        max-width: 100%;
        max-height: calc(100vh - 40px); // Subtracting padding and margins for full view
        border-radius: 8px;
        object-fit: contain;  // Ensures the image scales down while maintaining aspect ratio
    }
}
