.tag-input{
    display: flex;
    flex-wrap: wrap;

    .tag{
        border-radius: 5px;
        padding: 7px 20px;
        width: fit-content;
        margin-bottom: 10px;
        margin-right: 10px;
        height: fit-content;
        word-break: break-word;
        background-color: #f7fafc;
        box-shadow: 0 3px 2px rgba(233, 236, 239,5%);
        color: #212529
    }
}