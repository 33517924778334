.admin-navbar {
  margin-bottom: 16px;
  height: 79px;

  .navbar-bottom-background {
    height: 88px;
    background: #5428e0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 78px;
    border-color: #5428e0;
  }

  .search-select-scroll {
    box-shadow: 1px 2px 6px rgb(168, 167, 167);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .search-select-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  .search-select-scroll::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  .search-select-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgb(177, 177, 177);
    height: 3px;
  }

  .notification-dropdown-menu {
    right: -20px !important;
    top: 35px !important;
    left: auto !important;

    .list-group {
      max-height: 250px;
      overflow-y: auto;
    }

    .notification {
      &.unread {
        background-color: #e6f7ff;
      }
    }
  }

  .notification-dropdown-width {
    width: 420px;
  }

  .employee-notification-menu {
    top: auto !important;
    left: -200px !important;
  }
}

.search-bar-icon {
  display: none;
}

.responsive-search {
  width: 100%;
}

.language-select {
  top: 30px !important;
  left: -80px !important;
}

.company-selector {
  width: 300px;
}

.profile-dropdown {
  left: 10px !important;
  top: 55px !important;
}

@media (min-width: 769px) {
  .responsive-search {
    width: 240px;
  }
  .close-xmark {
    display: none;
  }
}

@media (min-width: 820px) {
  .responsive-search {
    width: 280px;
  }
}

@media (max-width: 991px) {
  .admin-navbar {
    .notification-dropdown-menu {
      top: 35px !important;
      right: -80px !important;
    }

    .employee-notification-menu {
      top: auto !important;
      left: -300px !important;
    }

    .profile-dropdown {
      left: -45px !important;
      top: 55px !important;
    }

    .language-select {
      top: 30px !important;
      left: -48px !important;
      min-width: 100px;
    }
  }
}

@media (max-width: 768px) {
  .search-bar-icon {
    display: inline-block;
  }

  .navbarResponsive {
    width: 100%;
  }

  .admin-navbar {
    .notification-dropdown-menu {
      top: 35px !important;
      right: -80px !important;
    }

    .employee-notification-menu {
      top: -10px !important;
      left: 30px !important;
    }
  }
}

@media(max-width: 575px) {
  .admin-navbar {
    .notification-dropdown-menu {
      top: 65px !important;
      right: 80px !important;
    }

    .employee-notification-menu {
      top: 50px !important;
      left: 25px !important;
    }
    .notification-dropdown-width {
      width: 320px !important;
    }  
  }
}


@media(max-width: 535px) {
  .company-selector {
    width: 200px;
  }
}

@media(max-width: 475px) {
  .admin-navbar {
    .notification-dropdown-menu {
      top: 65px !important;
      right: 20px !important;
    }

    .employee-notification-menu {
      top: 50px !important;
      left: 15px !important;
    }
  }
  .company-selector {
    width: 160px;
  }
}